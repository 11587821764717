.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;


  .modal {
    display: flex;
    width: 560px;
    height: 30%;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: 25px;

    .closeButton {
      height: 40px;
      width: 100px;
      position: absolute;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      padding: 1px 0px;
      background-color: #fd0000;
      color: white;
      margin-left: 458px;
      font-size: 16px;
      font-weight: 900;
      border: none;
      border-radius: 20px;
    }

    .listContainer {
      display: flex;
      flex: 0.9;
      flex-direction: column;
      align-items: center;
      padding: 20px;



      .heading {
        font-size: 30px;
        text-align: center;
        color: black;
      }

      .list1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 25px;
        margin-bottom: 20px;
        padding-bottom: 15px;
        overflow-y: scroll;
        align-self: center;
        align-content: center;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);


      }

      .button {
        width: 40%;
        padding: 10px 0px;
        background-color: #002267;
        color: white;
        font-size: 15px;
        font-weight: 600;
        border: none;
        border-radius: 50px;
      }
    }
  }
}