@import url('https://fonts.googleapis.com/css2?family=Dhurjati&display=swap');

@font-face {
    font-family: "custom-font";
}

.fade-in {
  animation: fadeIn ease 1.5s;
  -webkit-animation: fadeIn ease 1.5s;
  -moz-animation: fadeIn ease 1.5s;
  -o-animation: fadeIn ease 1.5s;
  -ms-animation: fadeIn ease 1.5s;
  font-family: "Dhurjati";
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.valid-color:nth-child(1) {
    color: aquamarine;
}

.valid-color:nth-child(2) {
    color: rgb(140, 190, 255); /* Lighter blue for better contrast */
}

.valid-color:nth-child(3) {
    color: rgb(140, 185, 255); /* Slightly adjusted for variation */
}

.valid-color:nth-child(4) {
    color: rgb(170, 140, 255); /* Lightened purple for better visibility */
}

.valid-color:nth-child(5) {
    color: rgb(255, 140, 230); /* Brighter pink for contrast */
}

.valid-color:nth-child(6) {
    color: aquamarine; /* Repeats the first color for consistency */
}
