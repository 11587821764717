@import "../../../static/scss/colors";


.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;

  .modal {
    display: flex;
    width: 90%;
    height: 95%;
    border-radius: 25px;

    .addFeeForm {
      flex: 1;
      background-color: #fff;
      border-radius: 25px;
      display: flex;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
      color: lightslategrey;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 30px;
      text-transform: uppercase;
      padding: 10px;
      .btnClear {
        
        height: 40px;
        border-radius: 4px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        cursor: pointer;
        font-style: bold;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        background: #142700;
      }


      .btnClear2 {
        
        height: 40px;
        border-radius: 4px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        cursor: pointer;
        font-style: bold;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        background: #003402;
      }
      .btnClear3 {
        
        height: 40px;
        border-radius: 4px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        cursor: pointer;
        font-style: bold;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        background: #ad0101;
      }
      .delete {
        
        height: 30px;
        border-radius: 4px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        cursor: pointer;
        font-style: bold;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
        background: #660000;
      }
      .copy {
        
        height: 30px;
        margin: 10;
        border-radius: 4px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        cursor: pointer;
        font-style: bold;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
        background: #012f38;
      }
      .addMultipleUser {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #18a0fb;
        text-transform: none;
        margin: 10px 20px;
        cursor: pointer;
      }

      .title {
        width: 100%;
        font-weight: 600;
        min-height: 5rem;
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #002267;
        padding: 30px 0px 10px;
        border-bottom: 0.4px solid #ededed;
      }

      .feeForm {
        padding: 20px;

        .error {
          font-size: 12px;
          line-height: 1;
          color: rgb(221, 73, 73);
          transform: translateY(-50%);
        }

        .formFullWidth {
          margin: 10px 0;
          width: 100%;
          height: 56px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          border-radius: 30px;

          .ant-select-selector {
            border-radius: 30px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              outline: 0;
              margin: auto 15px;
              border: 0;
              -webkit-highlight: none;
              font-style: normal;
              font-weight: normal;
              font-size: 19px;
              line-height: 23px;
              text-transform: uppercase;

              &:focus {
                outline: 0;
                border: 0;
              }
            }
          }

          .error {
            height: unset;
          }
        }

        .formHalfWidth {
          margin: 10px 0;
          width: 48%;
          padding: 5px;
          height: 45px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          border-radius: 30px;

          .ant-select-selector {
            border-radius: 30px;
            cursor: pointer !important;
          }

          >div {
            height: 80%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              outline: 0;
              margin: auto 5px;
              border: 0;
              -webkit-highlight: none;
              font-style: normal;
              font-weight: normal;
              font-size: 19px;
              line-height: 23px;
              text-transform: uppercase;

              &:focus {
                outline: 0;
                border: 0;
              }
            }
          }

          .error {
            height: unset;
          }
        }

        .formDistWidth {
          margin: 12px 0;
          flex: 0 0 54%;
          height: 55px;
          border-radius: 4px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

          .button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            background: rgb(255, 255, 255);
            border-radius: 40px;

            svg {
              margin-right: 10px;
              height: 50%;
              width: auto;
            }

            input {
              display: none;
            }
          }

          .uploadButton {
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 11px;
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 23px;
            text-transform: uppercase;
            color: #c0c0c0;
          }

          .error {
            height: unset;
          }
        }

        .btns {
          display: flex;
          width: 100%;

          .radioItem {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 10px 0;
            margin-right: 20px;

            .radioCircle {
              height: 30px;
              width: 30px;
              border-radius: 15px;
              background: white;
              display: flex;
              justify-content: center;
              border-color: black;
              border-width: 1px;
              border-style: solid;
              align-items: center;

              .radioInCircle {
                height: 28px;
                width: 28px;
                border-radius: 14px;
                background: $primary-green;
              }
            }

            span {
              margin-left: 15px;
              font-size: 22px;
            }
          }
        }

        .btnClear {
          margin: 16px auto;
          flex: 0 0 30%;
          height: 55px;
          border-radius: 4px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 32px;
          border: none;
          cursor: pointer;
          font-style: bold;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          background: #888888;
        }

        .formButtonWidth {
          margin: 16px auto;
          flex: 0 0 40%;
          height: 55px;
          border-radius: 4px;

          >button {
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 32px;
            border: none;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            background: linear-gradient(to right, #002267, #ff7e3d);

            svg {
              margin-right: 10px;
              height: 50%;
              width: auto;
            }
          }
        }

        .formPinWidth {
          margin: 12px auto;
          flex: 0 0 45%;
          height: 55px;
          border-radius: 4px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

          .error {
            height: unset;
          }
        }

        .group {
          position: relative;
          background: white;
          margin-bottom: 2rem;
          display: flex;
          align-items: flex-end;
          border-radius: 30px;

          input,
          button {
            width: 100%;
          }

          input[type="password"],
          input[type="text"],
          input[type="date"] {
            background: none;
            color: #000000;
            font-family: 'Times New Roman', Times, serif;
            margin-left: 30px;
            font-weight: normal;

            font-size: 20px;
            padding: 10px;

            display: block;
            border: none;

            &:focus {
              outline: none;
            }

            &:focus~label,
            &:valid~label {
              top: -0;
              font-size: 12px;

              color: #000000;
            }
          }

          input[type="password"] {
            letter-spacing: 0.3em;
          }

          input[type="date"]:placeholder-shown {
            text-transform: uppercase;
          }

          label {
            position: absolute;
            pointer-events: none;
            left: 11px;
            top: 10px;
            transition: 400ms ease all;
            margin-left: 15px;
            font-style: normal;
            font-weight: normal;
            font-size: 19px;
            line-height: 23px;
            color: #c0c0c0;
            text-transform: uppercase;
          }

          .eye {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            color: $primary-color;
          }

          .error {
            transform: none;
            position: absolute;
            bottom: -20px;
            left: 25px;
            right: 0;
          }
        }



        .closeButton {
          height: 40px;
          width: 50px;
          position: relative;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

          align-self: center;
          padding: 1px 0px;
          background-color: #02520a;
          color: white;
          font-size: 22px;
          font-weight: 900;
          border: none;
          border-radius: 20px;
        }



      }
    }

    .radioGroup {
      margin: 0 auto;
      display: flex;
      flex-direction: row;

      .radioItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 0;
        margin-right: 20px;

        .radioCircle {
          height: 30px;
          width: 30px;
          border-radius: 15px;
          background: white;
          display: flex;
          justify-content: center;
          border-color: black;
          border-width: 1px;
          border-style: solid;
          align-items: center;

          .radioInCircle {
            height: 28px;
            width: 28px;
            border-radius: 14px;
            background: $primary-green;
          }
        }

        span {
          margin-left: 15px;
          font-size: 22px;
        }
      }
    }
  }

  .closeButton {
    height: 40px;
    width: 5%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 2%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 14px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }
  .closeButton2 {
    height: 40px;
    width: 10%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 2%;
    bottom: 2%;
    padding: 1px 0px;
    background-color: #002f01;
    color: white;
    font-size: 14px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }
}