@import "../../../static/scss/colors";

.addBookModal {
  height: 85%;
  width: 90%;
  background: white;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .head {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    background: rgb(0, 66, 77);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    height: 40px;
    width: 10%;
    position: absolute;
    right: 3%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #910e0e;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .content {
    height: 0;
    flex: 1;
    margin: 10px 0;

    form {
      padding: 20px 40px;
      display: flex;
      flex-direction: column;



      span {
        position: relative;
        text-align: center;
        margin-top: 20px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 23px;
        color: #006711;
      }














      .titles {
        flex-direction: row;
        display: flex;
        width: 100%;
        height: 40px;
        background-color: #ffffff;
        margin: 10px;
        align-content: center;
        align-items: center;

        .formFullWidth {
          margin: 10px 0;
          width: 100%;
          height: 56px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          border-radius: 30px;

          .ant-select-selector {
            border-radius: 30px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              outline: 0;
              margin: auto 15px;
              border: 0;
              -webkit-highlight: none;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 23px;
              text-transform: uppercase;

              &:focus {
                outline: 0;
                border: 0;
              }
            }
          }

          .error {
            height: unset;
          }
        }




        .formFullWidth2 {
          width: 100%;
          height: 40px;
          border-radius: 30px;



          .ant-select-selector {
            border-radius: 20px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 23px;

              margin-top: 15px;

              &:focus {
                outline: 0;
                border: 0;
              }

              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #992b51;

              }
            }
          }

          .error {
            height: unset;
          }
        }

        .addModal {
          height: 30px;
          width: 25px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          background-color: #008f30;
          border: none;
          position: relative;
          border-radius: 20px;
          cursor: pointer;

          h3 {
            position: relative;
            width: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
            color: #ffffff;
          }

        }

        .group {
          position: relative;
          height: 40px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

          background: rgb(255, 255, 255);
          display: flex;

          border-radius: 20px;

          label {
            position: absolute;
            pointer-events: none;
            left: 11px;
            top: -0;
            transition: 400ms ease all;
            margin-left: 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 23px;
            color: #006711;
          }


          input {
            background: none;
            color: #006711;
            font-family: 'arial';
            margin-left: 10px;
            margin-top: 10px;
            margin-right: 10px;

            font-weight: 600;
            width: 100%;
            font-size: 16px;
            display: block;
            border: none;
            border-radius: 20px;

            &:focus {
              outline: none;
              background: none;

            }

            &:focus~label {
              background: none;

            }


            &:valid~label {
              top: -0;
              font-size: 12px;
              background: none;

              color: #016c11;
            }

          }



        }

        button {
          height: 50px;
          width: 300px;
          margin: 10px;
          border-radius: 8px;
          padding: 0 20px;
          border: 1px solid $overlay-black;
          margin-bottom: 15px;
        }

        .add {
          height: 40px;
          width: 50%;
          position: relative;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          margin-left: 10px;
          padding: 1px 0px;
          margin-top: 20%;
          margin-left: 30%;
          background-color: #007c25;
          color: white;
          font-size: 16px;
          font-weight: 900;
          border: none;
          border-radius: 30px;
          cursor: pointer
        }


      }




      .titlesSub {
        flex-direction: column;
        width: 700px;
        background-color: #ffc1c1;
        margin: 10px;
        border-radius: 20px;
        align-content: center;
        align-items: center;

        .formFullWidth {
          margin: 10px 0;
          width: auto;
          height: 56px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          border-radius: 30px;

          .ant-select-selector {
            border-radius: 30px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              outline: 0;
              margin: auto 15px;
              border: 0;
              -webkit-highlight: none;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 23px;
              text-transform: uppercase;

              &:focus {
                outline: 0;
                border: 0;
              }
            }
          }

          .error {
            height: unset;
          }
        }


        .imgWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;

          img {
            height: 330px;
            width: auto;
            object-fit: contain;
          }

          input {
            display: none;
          }
        }

        .formFullWidth2 {
          width: 100%;
          height: 40px;
          border-radius: 30px;



          .ant-select-selector {
            border-radius: 20px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 23px;

              margin-top: 15px;

              &:focus {
                outline: 0;
                border: 0;
              }

              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #992b51;

              }
            }
          }

          .error {
            height: unset;
          }
        }

        .addModal {
          height: 30px;
          width: 25px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          background-color: #008f30;
          border: none;
          position: relative;
          border-radius: 20px;
          cursor: pointer;

          h3 {
            position: relative;
            width: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
            color: #ffffff;
          }

        }

        .group {
          position: relative;
          height: 40px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          width: auto;
          background: rgb(255, 255, 255);
          display: flex;

          border-radius: 20px;

          label {
            position: absolute;
            pointer-events: none;
            left: 11px;
            top: -0;
            transition: 400ms ease all;
            margin-left: 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 23px;
            color: #006711;
          }


          input {
            background: none;
            color: #006711;
            font-family: 'arial';
            margin-left: 10px;
            margin-top: 10px;
            margin-right: 10px;

            font-weight: 600;
            width: 100%;
            font-size: 16px;
            display: block;
            border: none;
            border-radius: 20px;

            &:focus {
              outline: none;
              background: none;

            }

            &:focus~label {
              background: none;

            }


            &:valid~label {
              top: -0;
              font-size: 12px;
              background: none;

              color: #016c11;
            }

          }



        }

        button {
          height: 50px;
          width: 300px;
          margin: 10px;
          border-radius: 8px;
          padding: 0 20px;
          border: 1px solid $overlay-black;
          margin-bottom: 15px;
        }

        .add {
          height: 40px;
          width: 50%;
          position: relative;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          margin-left: 10px;
          padding: 1px 0px;
          margin-top: 20%;
          margin-left: 5%;
          background-color: #007c25;
          color: white;
          font-size: 16px;
          font-weight: 900;
          border: none;
          border-radius: 30px;
          cursor: pointer
        }


      }









      .group {
        position: relative;
        margin: 10px;
        width: 40%;
        height: 40px;
        padding: 1px 0px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        background: rgb(255, 255, 255);
        display: flex;

        border-radius: 20px;

        input {
          background: none;
          color: #000000;
          font-family: 'arial';
          margin-left: 10px;
          font-weight: 600;
          width: 95%;
          font-size: 16px;
          padding: 1px;
          display: block;
          border: none;

          &:focus {
            outline: none;
          }

          &:focus~label,
          &:valid~label {
            top: -0;
            font-size: 12px;

            color: #016c11;
          }

        }

      }



    }
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $overlay-black;
  display: flex;
}