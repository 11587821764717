:root {
  --input-bg-color: #ffffff;
  --font-color: #333;
  --border-radius: 5px;
  --transition-speed: 0.3s;
  --font-size: 1rem;
  --scrollbar-thumb-color: #42494A;
  --scrollbar-track-color: rgba(129, 127, 127, 0.5);
}

.customInput {
  position: relative;
  margin: 10px 5px;
  z-index: 0;
  height: 48px; /* Adjust as per your design */
}

.input__field {
  position: absolute;
  height: 100%;
  width: 100%;
  font-weight: 600;
  transition: border-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  font-size: var(--font-size);
  border: 1px solid #ccc; /* Added border */
  border-radius: var(--border-radius);
  padding: 8px;
}

.input__label {
  position: absolute;
  font-weight: 700;
  top: 50%;
  transform: translateY(-50%);
  transition: transform var(--transition-speed) cubic-bezier(0.075, 0.82, 0.165, 1), font-size var(--transition-speed) cubic-bezier(0.075, 0.82, 0.165, 1);
  background: var(--input-bg-color);
  margin-left: 12px;
  border-radius: var(--border-radius);
  padding: 0 5px;
  font-size: var(--font-size);
  cursor: text;
  color: var(--font-color);
}

.input__label2 {
  position: absolute;
  font-weight: 700;
  top: 50%;
  right: 0;
  transform: translateY(50%);
  background: var(--input-bg-color);
  margin-right: 12px;
  border-radius: var(--border-radius);
  padding: 0 5px;
  font-size: 12px;
  cursor: text;
  color: rgb(0, 120, 232);
}

.input__field:focus + .input__label,
.input__field:not(:placeholder-shown) + .input__label {
  top: -5px; /* Adjusted position */
  transform: translateY(0%);
  font-size: 0.75rem; /* Smaller font size */
}

#scrollBarEnable::-webkit-scrollbar {
  width: 10px;
}

#scrollBarEnable::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

#scrollBarEnable::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

.dropdown-visible {
  animation: fadeIn 0.3s;
}
.mandatory-asterisk {
  color: red;
}


input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


