@import "../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;





  .modal {
    display: flex;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;


    .listContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      padding: 20px;
      border: solid;
      border-right-color: rgb(170, 122, 122);
      border-width: 0px 1px 0px 0px;

      .actionContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;

        .button2 {
          width: 40%;
          margin: 5%;
          height: 40px;
          padding: 10px 0px;
          background-color: #0f003e;
          color: white;
          font-size: 14px;
          font-weight: 900;
          border: none;
          border-radius: 50px;
        }

      }

      .list {
        display: flex;
        flex-direction: column;
        height: 45%;
        width: 100%;
        background-color: rgb(255, 175, 175);
        border-radius: 20px;
        overflow-y: scroll;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

        .heading {
          font-size: 20px;
          margin-bottom: 0px;
          text-align: center;
          font-weight: 900;
          color: rgb(160, 5, 5);
        }






        .feeForm {
          width: 300px;

          .error {
            font-size: 12px;
            line-height: 1;
            color: rgb(221, 73, 73);
            transform: translateY(-50%);
          }

          .formFullWidth {
            width: 100%;
            height: 45px;
            margin: 12px;
            box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
            border-radius: 30px;

            .ant-select-selector {
              border-radius: 30px;
              cursor: pointer !important;
            }

            >div {
              height: 100%;
              border: 0;

              span {
                display: flex;
                align-items: center;
                outline: 0;
                margin: auto 15px;
                border: 0;
                -webkit-highlight: none;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;


                &:focus {
                  outline: 0;
                  border: 0;
                }
              }
            }

            .error {
              height: unset;
            }
          }

          .formFullWidth2 {
            width: 100%;
            height: 45px;

            box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
            border-radius: 30px;

            .ant-select-selector {
              border-radius: 30px;
              cursor: pointer !important;
            }

            >div {
              height: 100%;
              border: 0;

              span {
                display: flex;
                align-items: center;
                outline: 0;
                margin: auto 15px;
                border: 0;
                -webkit-highlight: none;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;


                &:focus {
                  outline: 0;
                  border: 0;
                }
              }
            }

            .error {
              height: unset;
            }
          }

          .btns {
            display: flex;
            margin-left: 36px;
            width: 90%;

            .btnClear {
              margin: 16px auto;
              flex: 0 0 40%;
              height: 45px;
              border-radius: 4px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 32px;
              border: none;
              cursor: pointer;
              font-style: bold;
              font-weight: 600;
              font-size: 14px;
              line-height: 26px;
              color: #ffffff;
              background: #888888;
            }

            .formButtonWidth {
              margin: 16px auto;
              flex: 0 0 40%;
              height: 55px;
              border-radius: 4px;

              >button {
                cursor: pointer;
                width: 100%;
                height: 45px;

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 32px;
                border: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #ffffff;
                background: #053aa3;

                svg {
                  margin-right: 10px;
                  height: 50%;
                  width: auto;
                }
              }
            }
          }


          .group {
            position: relative;
            display: flex;
            margin-left: 85px;
            border-radius: 30px;

            input,
            button {
              width: 100%;
            }

            input[type="password"],
            input[type="text"],


            
            input[type="number"] {
              background: none;
              color: #000000;
              font-family: 'arial';
              margin-left: 30px;
              font-weight: 900;
              font-size: 20px;
              margin-top: 18px;

              display: block;
              border: none;

              &:focus {
                outline: none;
              }

              &:focus~label,
              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #000000;
              }
            }
            input[type="date"] {
              background: none;
              color: #000000;
              font-family: 'arial';
              margin-left: 30px;
              font-weight: 400;
              font-size: 20px;

              display: block;
              border: none;

              &:focus {
                outline: none;
              }

              &:focus~label,
              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #000000;
              }
            }

            input[type="password"] {
              letter-spacing: 0.3em;
            }

            input[type="date"]:placeholder-shown {
              text-transform: uppercase;
              opacity: .5;

            }

            label {
              position: absolute;
              pointer-events: none;
              transition: 400ms ease all;
              margin-left: 15px;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 23px;
              color: #9b9a9a;
            }

            .eye {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 15px;
              margin: auto;
              color: $primary-color;
            }

            .error {
              transform: none;
              position: absolute;
              bottom: -20px;
              left: 25px;
              right: 0;
            }
          }
        }














      }

      .denominations {
        display: flex;
        flex-direction: column;
        height: 45%;
        width: 100%;
        background-color: rgb(255, 175, 175);
        border-radius: 20px;
        overflow-y: scroll;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

        .heading {
          font-size: 20px;
          margin-bottom: 0px;
          text-align: center;
          font-weight: 900;
          color: rgb(160, 5, 5);
        }


        .subDiv {
          flex-direction: row;
          margin-top: 20px;
          align-self: center;
          display: flex;

          .subHeads {
            font-size: 16px;
            width: 80px;
            margin-left: 20%;
            font-weight: 600;
            align-self: right;
            color: rgb(0, 0, 0);
          }

       
          .subAmounts {
            font-size: 16px;
            width: 40px;
            font-weight: 600;
            align-self: right;
            color: rgb(0, 0, 0);
          }
        }










      }







    }


    .listContainer2 {
      width: 77%;
      flex-direction: column;
      padding: 20px;


      .title {
        flex-direction: row;
        display: flex;


        .heading {
          font-size: 30px;
          width: 60%;
          margin-bottom: 1px;
          font-weight: 900;
          align-self: center;
          text-align: center;
          color: rgb(21, 0, 83);
        }



        .subDiv {
          width: 20%;

          .closeButton {
            height: 50px;
            width: 180px;
            padding: 1px 0px;
            background-color: #fd0000;
            color: white;
            font-size: 30px;
            font-weight: 900;
            border: none;
            border-radius: 20px;
          }
        }

      }

      .userTable {
        color: #ffffff;
        min-width: 100%;
        border-collapse: collapse;

        th {
          height: 100%;
          padding: 0;
        }

        tr {

          th,
          td {
            &:first-of-type {
              width: 80px;
              // max-width: 80px;
              min-width: 80px;
            }
          }
        }

        thead {
          tr {
            background: #ffffff;
            height: 35px;


            th {
              position: relative;
              background: #110243;
              font-weight: bold;
              color: #ffffff;
              position: sticky;
              z-index: 3;
              top: 0;

              &:first-of-type,
              &:nth-of-type(2) {
                z-index: 10;
              }
            }
          }
        }

        tbody {
          .tik {
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        tr {
          height: 50px;
          border-bottom: 1px solid rgb(70, 70, 70);

          th {
            color: white;
            font-weight: 400;
            white-space: nowrap;
            padding: 0 20px;
            text-align: left;
          }

          td {
            background: white;
            font-weight: 900;
            font-size: 14px;
            font-family: 'arial';
            white-space: nowrap;
            padding: 0 24px;
            text-align: left;
          }

          th {
            background: white;
            color: rgb(212, 16, 16);

            &:first-of-type {
              width: 85px;
              left: 0;
            }

            &:nth-of-type(2) {
              left: 80px;
            }

            &:first-of-type,
            &:nth-of-type(2) {
              position: sticky;
              z-index: 2;
            }
          }
        }


      }



    }


  }


  .group {
    position: absolute;
    right: 25%;
    top: 2%;
    width: 150px;
    height: 40px;
    padding: 1px 0px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    background: rgb(255, 255, 255);
    display: flex;

    border-radius: 20px;

    input,
    button {
      width: 100%;
    }

    input[type="password"],
    input[type="text"],


    input[type="date"] {
      background: none;
      color: #000000;
      font-family: 'arial';
      margin-left: 10px;
      font-weight: 600;
      width: 120px;
      font-size: 16px;
      padding: 1px;
      display: block;
      border: none;

      &:focus {
        outline: none;
      }

      &:focus~label,
      &:valid~label {
        top: -0;
        font-size: 12px;

        color: #000000;
      }
    }

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    input[type="date"]:placeholder-shown {
      text-transform: uppercase;
      opacity: .5;

    }

    label {
      position: absolute;
      pointer-events: none;
      left: 11px;
      top: 10px;
      transition: 400ms ease all;
      margin-left: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 23px;
      color: #ffffff;
    }

    .eye {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
      color: $primary-color;
    }

    .error {
      transform: none;
      position: absolute;
      bottom: -20px;
      left: 25px;
      right: 0;
    }
  }


  .reloadButton {
    height: 40px;
    width: 100px;
    position: absolute;
    right: 17.2%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    top: 2%;
    padding: 1px 0px;
    background-color: #00577c;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
  }


  .printBotton {
    height: 40px;
    width: 100px;
    position: absolute;
    right: 9.5%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    top: 2%;
    padding: 1px 0px;
    background-color: #00577c;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
  }

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}