.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.25);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;
}

.columnDisplay {
  flex-direction: column;
}

.modal {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 30%;
  background-color: #0e033e;
  border-radius: 25px;
  align-items: end;

  .closeButton {
    position: a;
    right: 3%;
    top: 1%;
    margin: 4px;
    font-weight: 900;
    border-radius: 100%;
    color: #f3f3f3;

    background-color: rgb(253, 0, 0);

  }

  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85%;
    margin: 4px;
    border-radius: 25px;

    background-color: white;

    .heading {
      font-size: 20px;
      margin-bottom: 26px;
      font-weight: 900;
      text-align: center;
      color: rgb(4, 17, 114);
    }

    .notice {
      font-size: 40px;
      margin-bottom: 26px;
      font-weight: 400;
      text-align: center;
      width: 80%;
      align-self: center;
      color: rgb(0, 124, 8);
    }

    .button {
      width: 50%;
      padding: 10px 0px;
      background-color: #002267;
      color: white;
      font-size: 15px;
      font-weight: 600;
      align-items: center;
      border: none;
      border-radius: 50px;
    }

  }
}
