@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import "~antd/dist/antd.css";
@import "_colors";
@import "mixin";

html {
  height: 100vh;
  display: flex;
  flex-direction: column;
  body {
    font-family: Montserrat !important;
    margin: 0;
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    #root {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      color: $primary-color;
      // background:#F2F3F8;
      &:fullscreen {
        background: #F2F3F8;
        width: 100vw;
        height: 100vh;
      }
      * {
        
        outline: none;
        box-sizing: border-box;
      }
      transform: scale(1) translate(0);
    }
    #recaptcha-container {
      transform: scale(0) translate(0);
      @include xxxl {
        zoom: 1.25;
      }
      @include xxl {
        zoom: 1.43;
      }
      @include xl {
        zoom: 1.6667;
      }
      @include lg {
        zoom: 2;
      }
      @include md {
        zoom: 2.5;
      }
    }
    @include xxxl {
      zoom: 0.8;
    }
    @include xxl {
      zoom: 0.7;
    }
    @include xl {
      zoom: 0.6;
    }
    @include lg {
      zoom: 0.5;
    }
    @include md {
      zoom: 0.4;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.Tooltip-Wrapper:not(:last-child) 
{
  border-right: solid #FFFFFF 2px;
}
