.group {
  position: relative;
  height: 40px;
  box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
  width: 100%;
  background: rgb(255, 255, 255);
  display: flex;

  border-radius: 20px;

  label {
    position: absolute;
    pointer-events: none;
    transition: 400ms ease all;
    margin-left: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 23px;
    color: #006711;
  }


  input {
    background: none;
    color: #006711;
    font-family: 'arial';
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;

    font-weight: 600;
    width: 100%;
    font-size: 16px;
    display: block;
    border: none;
    border-radius: 20px;

    &:focus {
      outline: none;
      background: none;

    }

    &:focus~label {
      background: none;

    }


    &:valid~label {
      top: -0;
      font-size: 12px;
      background: none;

      color: #016c11;
    }

  }



}