.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;


  .modal {
    display: flex;
    width: 560px;
    height: 45%;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: 25px;

    .closeButton {
      height: 40px;
      width: 100px;
      position: absolute;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      padding: 1px 0px;
      background-color: #fd0000;
      color: white;
      margin-left: 458px;
      font-size: 16px;
      font-weight: 900;
      border: none;
      border-radius: 20px;
    }

    .listContainer {
      display: flex;
      flex: 0.9;
      flex-direction: column;
      align-items: center;
      padding: 40px;

      .group {
        position: relative;
        height: 40px;

        box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

        background: rgb(255, 255, 255);
        display: flex;

        border-radius: 20px;

        label {
          position: absolute;
          pointer-events: none;
          left: 11px;
          top: -10;
          transition: 400ms ease all;
          margin-left: 15px;
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 23px;
          color: #006711;
        }


        input {
          background: none;
          color: #006711;
          font-family: 'arial';
          margin-left: 10px;
          margin-top: 30px;
          margin-right: 10px;
          margin-left: 30;

          font-weight: 600;
          width: 100%;
          font-size: 20px;
          display: block;
          border: none;
          border-radius: 20px;

          &:focus {
            outline: none;
            background: none;

          }

          &:focus~label {
            background: none;

          }


          &:valid~label {
            top: -10;
            font-size: 12px;
            background: none;

            color: #016c11;
          }

        }



      }

      .formFullWidth {
        width: 100%;
        box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
        border-radius: 30px;

        .ant-select-selector {
          border-radius: 20px;
          cursor: pointer !important;
        }
        
        >div {
          height: 100%;
          border: 0;

          span {
            display: flex;
            align-items: center;
            outline: 0;
            margin: auto 15px;
            border: 0;
            -webkit-highlight: none;
            font-style: normal;
            font-weight: 600;
            margin-left: 10;
            font-size: 15px;
            line-height: 30px;

            margin-top: 15px;

            &:focus {
              outline: 0;
              border: 0;
            }

            &:valid~label {
              top: -0;
              font-size: 12px;

              color: #016c11;

            }
          }
        }

        .error {
          height: unset;
        }
      }

      .heading {
        font-size: 30px;
        text-align: center;
        color: black;
      }

      .list1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 25px;
        margin-bottom: 20px;
        padding-bottom: 15px;
        overflow-y: scroll;
        align-self: center;
        align-content: center;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);


      }

      .button {
        width: 40%;
        padding: 10px 0px;
        background-color: #002267;
        color: white;
        font-size: 15px;
        font-weight: 600;
        border: none;
        border-radius: 50px;
      }
    }
  }
}