
.dashLayout {

  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;

  .navbar {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #002267;
    margin-bottom: 0;
    filter: drop-shadow(4px 3px 4px rgb(0 0 0 / 10%));

    .avatarCollege {
      height: 40px;
      margin-left: 1rem;
      border-radius: 15px;
      padding: 5px;

    }

    .title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }

    .btUser {
      margin-left: auto;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        margin-right: 10px;
      }

      .avatarUser {
        height: 35px;
        width: 35px;
        position: relative;
        padding: 1pt;
        margin-right: 1rem;
        border-radius: 50px;
        box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
        background: rgb(238, 238, 238);
      }
    }

    .fs {
      height: 30px;
      width: 30px;
      padding: 20px;
      filter: brightness(0) invert(1);
      cursor: pointer;

      &.true:hover {
        padding: 22px;
      }

      &.false:hover {
        padding: 18px;
      }
    }
  }

  .content {
    flex: 1;
    height: 0;
  }

  .bottombar {
    background: #002267;
    height: 40px;
    margin: 5px auto;
    display: flex;
    align-items: center;
    border-radius: 30px;
    transition: all 1ms ease;
    z-index: 1;

    .icon {
      margin: 0 20px;
      color: white;
      width: 30px;
      object-fit: contain;
      filter: invert(1);

      &:hover,
      &.active {
        filter: invert(1) drop-shadow(0 0 0.75rem white);
        transform: scale(1.05);
      }
    }
  }


}

