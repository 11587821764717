$role-color: #002267;

.rolePage {
  // display: flex;
  // flex-direction: column;
  height: 0;
  flex: 1;
  padding: 0 25px;
  // width: 1060px;
  overflow: hidden;
  justify-content: center;
  align-items: center;


  .role_container {
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 9px rgb(0 0 0 / 20%));
    border-radius: 1rem;
    margin-top: 15px;
    padding: 1rem .1rem;
    height: 100%;
    max-height: 96%;

    align-items: center;

  }

  .title {
    color: $role-color;
    font-weight: bolder;
    font-size: 2rem;
  }

  .underline {
    position: relative;
  }

  .underline:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 35px;

    border-bottom: 5px solid #ff7e3d;
  }

  .card_container {
    width: 100%;
    height: 100%;
    align-items: center;
    align-self: center;
    .role_card {
      background-color: #025c5e;
      border-radius: 1rem;
      display: flex;
      flex-direction: row;
      width: 28rem;
      height: 7rem;
      border: white;
      align-items: center;
      align-self: center;
      margin: 20px;
      float: left;


      .icon {
        filter: drop-shadow(0px 4px 4px rgb(0 0 0 / 15%));
        border-radius: 1rem;
        margin: .5rem;
        width: 6rem;
        height: 6rem;
        background-color: #ffffff;
      }

      .iconSize {
        width: 6rem;
        height: 6rem;
        padding: .8rem;
      }

      h2 {
        color: #ffffff;
        font-size: 19px;
        font-weight: 600;
        align-self: center;
      }
      h3 {
        color: #ffffff;
        font-size: 17px;
        font-weight: 600;
        align-self: left;
      }
      h4 {
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        align-self: left;
      }

      img {
        right: 10px;
      }
    }
  }



  .role_card:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.closeButton {
  height: 40px;
  width: 100px;
  position: fixed;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 101;
  right: 1.9%;
  top: 2%;
  padding: 1px 0px;
  background-color: #fd0000;
  color: white;
  font-size: 16px;
  font-weight: 900;
  border: none;
  border-radius: 20px;
}