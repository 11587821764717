@import "../../../../static/scss/colors";

.addBookModal {
  height: 90%;
  width: 98%;
  background: white;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .listContainer2 {
    width: 100%;
    padding: 20px;
    margin-top: 38px;

    .notice {
      font-size: 40px;
      margin-bottom: 26px;
      font-weight: 400;
      text-align: center;
      width: 80%;
      align-self: center;
      color: rgb(0, 124, 8);
    }




  }

  .head {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    background: rgb(0, 66, 77);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    height: 40px;
    width: 10%;
    position: absolute;
    right: 3%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #910e0e;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .add {
    height: 40px;
    width: 50%;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    margin-left: 30%;
    background-color: #007c25;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .delete {
    height: 40px;
    width: 10%;
    position: absolute;
    left: 3%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #ff0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .load {
    height: 40px;
    width: 10%;
    position: absolute;
    right: 23%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #189f01;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .submit {
    height: 40px;
    width: 10%;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: center;
    padding: 1px 0px;
    background-color: #0e5c01;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .mainQ {
    height: 30px;
    width: 4%;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: center;
    padding: 1px 0px;
    background-color: #063c57;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .subQ {
    height: 30px;
    width: 2%;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: center;
    padding: 1px 0px;
    background-color: #5c013b;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  span {
    display: flex;
    align-items: center;
    outline: 0;
    margin: auto 15px;
    border: 0;
    -webkit-highlight: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    margin-top: 15px;

    &:focus {
      outline: 0;
      border: 0;
    }

    &:valid~label {
      top: -0;
      font-size: 12px;
      color: #016c11;
    }
  }

  .titlesSub {
    flex-direction: column;
    width: 700px;
    background-color: #c1eaff;
    margin: 10px;
    border-radius: 20px;
    align-content: center;
    align-items: center;

    .add {
      height: 40px;
      width: 50%;
      position: relative;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      margin-left: 10px;
      padding: 1px 0px;
      margin-top: 20%;
      margin-left: 5%;
      background-color: #007c25;
      color: white;
      font-size: 16px;
      font-weight: 900;
      border: none;
      border-radius: 30px;
      cursor: pointer
    }
  }
}