@import "../../../../../static/scss/colors";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.8);
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;
}

.columnDisplay {
  flex-direction: row;
}

.modal {
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 80%;
  background-color: #f4f4f4;
  border-radius: 25px;


  .detailsDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    padding: 2%;



    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-radius: 25px;

      img {
        width: 12%;
        border-radius: 2%;
      }

      .typoContainer {
        width: 80%;
        align-items: center;
        align-content: center;
        text-align: center;


        span {
          font-size: 16px;
          font-weight: 900;
          color: #000000;
          margin: 0;

        }

        p {
          font-size: 12px;
          margin: 0;
          font-weight: 600;
          color: #000000;

          text-align: center;
          align-self: center;

        }


      }
    }

    .userDetials {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-radius: 25px;

      .addressBar {
        width: 80%;



        span {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin: 0;

        }

        p {
          font-size: 12px;
          margin: 0;
          font-weight: 600;
          color: #000000;


        }


      }
    }

    .typoContainer2 {
      display: flex;
      flex-direction: row;
      margin-top: 3%;
      align-self: center;
      align-items: center;
      align-content: center;

      .userTable {
        color: #ffffff;
        min-width: 100%;
        border-collapse: collapse;

        th {
          height: 100%;
          padding: 0;
        }

        tr {

          th,
          td {
            &:first-of-type {
              width: 80px;
              // max-width: 80px;
              min-width: 80px;
            }
          }
        }

        thead {
          tr {
            background: #ffffff;
            height: 25px;


            th {
              position: relative;
              background: #110243;
              font-weight: bold;
              color: #ffffff;
              position: sticky;
              z-index: 3;
              top: 0;

              &:first-of-type,
              &:nth-of-type(2) {
                z-index: 10;
              }
            }
          }
        }

        tbody {
          .tik {
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        tr {
          height: 25px;
          border-bottom: 1px solid rgb(70, 70, 70);

          th {
            color: white;
            font-weight: 400;
            white-space: nowrap;
            padding: 0 20px;
            text-align: left;
          }

          td {
            background: white;
            font-weight: 900;
            font-size: 14px;
            font-family: 'arial';
            white-space: nowrap;
            padding: 0 24px;
            text-align: left;
          }

          th {
            background: white;
            color: rgb(212, 16, 16);

            &:first-of-type {
              width: 85px;
              left: 0;
            }

            &:nth-of-type(2) {
              left: 80px;
            }

            &:first-of-type,
            &:nth-of-type(2) {
              position: sticky;
              z-index: 2;
            }
          }
        }
      }

      .collectedData {
        height: 130px;
        width: 150px;

        border-radius: 10%;
        margin: 1%;
        background-color: #ffffff;

        p {
          font-size: 14px;
          margin: 0;
          width: 150px;
          font-weight: 600;

          text-align: center;
          align-self: center;

        }

      }
    }
  }


  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    left: 30%;
    top: 7%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .printButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 30%;
    top: 7%;
    padding: 1px 0px;
    background-color: #02770c;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}