.closeButton {
  height: 40px;
  width: 100px;
  position: absolute;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  right: 1.9%;
  top: 2%;
  padding: 1px 0px;
  background-color: #fd0000;
  color: white;
  font-size: 16px;
  font-weight: 900;
  text-transform: none;
  border: none;
  border-radius: 20px;
}

.typoContainer,
.detailItem {
  padding: 8px 12px;
}

.subTitle {
  background: #2C3E50;
  /* A dark shade of blue */
  color: #ECF0F1;
  /* A very light gray, for contrast */
  padding: 10px 12px;
  font-weight: 600;
  /* Slightly bolder */
  font-size: 1.1em;
  /* A bit larger than standard text */
  margin-top: 10px;
}

.contentsStyle {
  color: #34495E;
  /* A medium-dark shade of blue */
  font-weight: 500;
  /* Medium bold */
}

.valueStyle {
  color: #7F8C8D;
  /* A shade of gray */
  font-weight: 400;
  /* Regular weight */
}

/* Adding a hover effect for better interactivity */
.detailItem:hover {
  background-color: #FAFAFA;
  /* A very light gray */
}

.tableView {
  flex: 0 0 98%;
  margin: 10px 20px 30px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  .tableHeader {
    background-color: #344955; // Dark slate
    display: flex;
    border-radius: 15px 15px 0 0;
    align-items: center;

    h4 {
      color: #F9EBB2; // Soft gold for contrast
      flex: 2;
      font-size: 1.4rem;
      font-weight: 600;
      margin: 10px 40px;
    }
  }

  .tableWrap {
    flex-grow: 1;
    overflow: auto;
    background-color: #FAF9F9; // Off white for the main background
    border-radius: 0 0 15px 15px;
    position: relative;

    /* Custom scrollbar styles */
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #B0BEC5;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background-color: #E8EEF2;
    }

    .tableContainer {
      max-height: 400px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #B0BEC5 #E8EEF2;
    }

    .userTable {
      color: #232F34; // Almost black for main text
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          background: #F4D8CD; // Light muted rose

          th {
            background: #F9AA8F; // Soft salmon for column headers
            font-weight: 600;
            font-size: large;
            color: #232F34; // Dark for contrast
            position: sticky;
            top: 0;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #d6d6d6;

          td {
            padding: 0 20px;
            text-align: left;
            font-size: 18px;
          }
        }
      }

      th,
      td {
        padding: 12px 15px;
        white-space: nowrap;
      }

      th {
        color: #5C3030; // Darker shade of rose
      }
    }
  }
}

.nextAdmn {
  height: 50px;
  width: 180px;
  margin: 12px;
  position: relative;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1px 0px;
  background-color: #E74C3C; // Vibrant red
  color: white;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #D62C19; // Slightly darker red on hover
  }
}