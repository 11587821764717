@import "../../../static/scss/colors";

.signUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 45rem;
  max-height: fit-content;
  // width: 45rem;
  // border-radius: 20px;
  background-color: white;

  .title {
    background: #002267;
    width: 100%;
    font-weight: 600;
    min-height: 5rem;
    // border-radius: 20px 20px 0 0;
    text-align: center;
    font-size: 25px;
    color: white;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 17px;
  }

  .forms {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .error {
      font-size: 12px;
      line-height: 1;
      color: rgb(221, 73, 73);
      transform: translateY(-50%);
    }

    .formFullWidth {
      margin: 10px 0;
      flex: 0 0 100%;
      height: 56px;

      box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.3);
      border-radius: 30px;

      .ant-select-selector {
        border-radius: 30px;
      }

      >div {
        height: 100%;
        border: 0;

        span {
          display: flex;
          align-items: center;
          outline: 0;
          height: 15px;
          margin: auto 15px;
          border: 0;
          -webkit-highlight: none;
          font-style: normal;
          font-weight: normal;
          font-size: 19px;
          line-height: 23px;
          text-transform: uppercase;
          color: #c0c0c0;

          &:focus {
            outline: 0;
            border: 0;
          }
        }
      }

      .error {
        height: unset;
      }
    }

    .formDistWidth {
      margin: 12px 0;
      flex: 0 0 54%;
      height: 55px;
      border-radius: 4px;
      box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.3);

      .button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background: rgb(255, 255, 255);
        border-radius: 40px;

        svg {
          margin-right: 10px;
          height: 50%;
          width: auto;
        }

        input {
          display: none;
        }
      }

      .uploadButton {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 11px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        text-transform: uppercase;
        color: #c0c0c0;
      }

      .error {
        height: unset;
      }
    }

    .formButtonWidth {
      margin: 16px auto;
      flex: 0 0 40%;
      height: 55px;
      border-radius: 4px;

      >button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;

        font-size: 1.6rem;
        letter-spacing: 3px;
        color: white;
        background: linear-gradient(to right, #002267, #ff7e3d);

        svg {
          margin-right: 10px;
          height: 50%;
          width: auto;
        }
      }
    }

    .formPinWidth {
      margin: 12px auto;
      flex: 0 0 45%;
      height: 55px;
      border-radius: 4px;
      box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.3);

      .error {
        height: unset;
      }
    }

    .group {
      position: relative;
      background: white;
      display: flex;
      align-items: flex-end;
      border-radius: 30px;

      // border: 0;
      // box-shadow: none;
      input,
      button {
        width: 100%;
      }

      input[type="password"],
      input[type="text"] {
        background: none;
        color: #505050;
        font-size: 18px;
        padding: 10px;
        display: block;
        border: none;
        // border-radius: 10px;
        // box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.3);

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #000000;
        }
      }

      input[type="password"] {
        letter-spacing: 0.3em;
      }

      label {
        position: absolute;
        pointer-events: none;
        left: 11px;
        top: 10px;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #c0c0c0;
        text-transform: uppercase;
      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -15px;
        left: 25px;
        right: 0;
      }
    }
  }
}