@import "../../../static/scss/colors";

.timeShedulePage {
  padding: 80px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;

  .title {
    font-size: 36px;
    color: white;
    line-height: 1;
    font-weight: 700;
    position: absolute;
    top: 20px;
    left: 30px;
    text-transform: uppercase;
    &::before{
      position: absolute;
      content: "";
      height: 4px;
      width: 51px;
      background-color: #FF7E3D;
      top: 35px;
    }
  }
  .content {
    height: 0 !important;
    flex: 1;
    > div {
      display: flex;
    }
    .batchDiv{
     
      height: fit-content;
      margin: 0px 30px;
    }
  }
  .buttons {
    display: flex;
    flex: 0 0 60px;
    align-items: center;
    margin-bottom: 20px;
    .btnClose,
    .btnSaveDefault,
    .btnLoadDefault,
    .btnUpdate {
      height: 90%;
      background: $primary-green;
      color: white;
      padding: 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 15px;
      border: 1px solid $primary-color;
      font-weight: 500;
      box-shadow: inset 0 0 4px #0000009f;
      cursor: pointer;
      &:hover {
        filter: contrast(1.5);
      }
    }
    .btnLoadDefault {
      margin-left: 30px;
      margin-right: auto;
    }
    .text {
      color: white;
      font-size: 22px;
      margin: 0 15px;
    }
    .datePicker {
      height: 75%;
      border-radius: 5px;
      box-shadow: 0 0 4px #0000009f;
      span {
        color: #1890ff;
      }
    }
    // temp
    .btnClose{
      background: repeating-linear-gradient(
        45deg, #002267, transparent 100px);
            font-weight: 700;
    }
    .btnUpdate {
      background: repeating-linear-gradient(
        45deg, #002267, transparent 100px);
        color:springgreen ;
            font-weight: 700;
      margin-left: auto;
    }
  }
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $overlay-black;
    display: flex;
  }
}
