@import "../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;

  right: 0px;
  bottom: 0px;
  z-index: 790;
  position: absolute;





  .modal {
    display: flex;
    flex-direction: column;
    width: 94%;
    height: 80%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;

    .reloadButton {
      height: 30px;
      width: 70px;
      position: relative;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      margin-left: 10px;

      padding: 1px 0px;
      background-color: #00577c;
      color: white;
      font-size: 10px;
      font-weight: 900;
      border: none;
      border-radius: 30px;
    }

    .group {
      position: relative;
      margin: 10px;
      width: 100%auto;
      align-self: center;
      height: 40px;
      padding: 1px 0px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      background: rgb(255, 255, 255);

      border-radius: 20px;

      input,
      button {
        width: 100%;
      }

      input[type="password"],


      input[type="text"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        font-weight: 600;
        width: 95%;
        font-size: 16px;
        padding: 1px;
        text-align: center;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          width: 90%;
          font-size: 12px;
          text-align: center;
          color: #016c11;
        }


      }

      input {
        background: none;
        color: #000000;
        font-family: 'arial';
        margin-left: 10px;
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;
        padding: 1px;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;
          text-align: center;

          color: #016c11;
        }

      }


      input[type="date"]:placeholder-shown {
        text-transform: uppercase;
        opacity: .3;

      }

      label {
        position: absolute;
        pointer-events: none;
        left: 11px;
        top: -0;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 23px;
        color: #006711;
        text-align: center;

      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -20px;
        left: 25px;
        right: 0;
      }

    }

    .controllDiv {
      display: flex;
      height: 800px;





      .mainCondainer {
        flex-direction: row;
        width: 100%;
        border-radius: 25px;
        background-color: rgb(255, 255, 255);


        .card_container {
          width: 100%;
          align-items: center;
          align-self: center;
          height: 100%;
          background-color: #ffffff;

          .issueButton {
            height: 30px;
            width: 90px;
            position: relative;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
            margin-left: 10px;
    
            padding: 1px 0px;
            background-color: #0094a4;
            color: white;
            font-size: 12px;
            font-weight: 900;
            border: none;
            border-radius: 30px;
          }
          .role_card {
            background-color: #025c5e;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            width: 7rem;
            height: 7rem;
            border: white;
            align-items: center;
            align-self: center;
            margin: 10px;
            margin-bottom: 150px;
            float: left;


            .icon {
              filter: drop-shadow(0px 4px 4px rgb(0 0 0 / 15%));
              border-radius: 1rem;
              margin: .5rem;
              width: 6rem;
              height: 6rem;
              background-color: #91ebf5;
            }

            .iconSize {
              width: 6rem;
              height: 6rem;
              padding: .8rem;
            }

            h3 {
              color: #00aadd;
              font-size: 12px;
              font-weight: 600;
              margin: 0;
              align-self: center;
              text-align: center;

            }

            h4 {
              color: #4902d9;
              font-size: 10px;
              margin: 0;
              font-weight: 600;
              align-self: center;
              text-align: center;
            }
            h2 {
              color: #ff0000;
              font-size: 20px;
              margin: 2;
              font-weight: 600;
              align-self: center;
              text-align: center;

            }
            h5 {
              color: #ff0000;
              font-size: 15px;
              margin: 2;
              font-weight: 600;
              align-self: center;
              text-align: center;
            }
            img {
              right: 10px;
            }
          }
        }




        .group {
          position: relative;
          margin: 10px;
          width: 80%auto;
          align-self: center;
          height: 40px;
          padding: 1px 0px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

          background: rgb(255, 255, 255);

          border-radius: 20px;

          input,
          button {
            width: 100%;
          }

          input[type="password"],


          input[type="text"] {
            background: none;
            color: #000000;
            font-family: 'arial';
            font-weight: 600;
            width: 95%;
            font-size: 16px;
            padding: 1px;
            text-align: center;
            display: block;
            border: none;

            &:focus {
              outline: none;
            }

            &:focus~label,
            &:valid~label {
              top: -0;
              width: 90%;
              font-size: 12px;
              text-align: center;
              color: #016c11;
            }


          }

          input {
            background: none;
            color: #000000;
            font-family: 'arial';
            margin-left: 10px;
            font-weight: 600;
            font-size: 16px;
            margin-top: 10px;
            padding: 1px;
            display: block;
            border: none;

            &:focus {
              outline: none;
            }

            &:focus~label,
            &:valid~label {
              top: -0;
              font-size: 12px;
              text-align: center;

              color: #016c11;
            }

          }


          input[type="date"]:placeholder-shown {
            text-transform: uppercase;
            opacity: .3;

          }

          label {
            position: absolute;
            pointer-events: none;
            left: 11px;
            top: -0;
            transition: 400ms ease all;
            margin-left: 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 23px;
            color: #006711;
            text-align: center;

          }

          .eye {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            color: $primary-color;
          }

          .error {
            transform: none;
            position: absolute;
            bottom: -20px;
            left: 25px;
            right: 0;
          }

        }



        .titleContainer {

          display: flex;
          flex-direction: column;
          height: 97%;
          width: 100%;
          align-self: center;
          position: relative;



          .nameOfBook {

            color: #002267;
            margin: 0;
            font-weight: 700;
            width: 80%;
            font-size: 20px;
            height: 60px;
            z-index: 22;
            text-align: center;
            align-self: center;

          }


          .typoContainer {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-self: center;

            h1 {
              color: #002267;
              margin: 0;
              font-weight: 700;
              font-size: 35px;
              align-self: center;

            }

            p {
              font-size: 20px;
              margin: 0;
              align-self: center;

            }
          }

          .avatar {

            // background-color: white;
            height: 60%;
            width: 70%;
            align-self: center;
            border-radius: 10%;

            .dp {
              height: 98%;
              width: 100%;
              align-self: center;

              border-radius: 10%;
            }
          }
        }

      }
    }


    .listContainer2 {
      width: 100%;
      height: 88%;
      padding: 10px;
      background-color: rgb(255, 255, 255);
      border-radius: 25px;
      margin-top: 3px;




      .headerPanel {

        display: flex;
        flex-direction: row;
        width: 100%;
        height: 10%;
        background-color: rgb(255, 255, 255);
        border-radius: 25px;

        .heading {
          font-size: 25px;
          width: 25%;
          font-weight: 900;

          align-self: left;
          color: rgb(55, 28, 136);
        }

        .paginationController {
          width: 75%;
          align-self: right;
          flex-direction: row;
          display: flex;
          margin-left: 20%;

          .heading {
            font-size: 15px;
            width: 55%;
            font-weight: 600;
            margin-left: 12px;
            align-self: left;
            color: rgb(28, 136, 32);
          }

          .reloadButton {
            height: 30px;
            width: 70px;
            position: relative;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
            margin-left: 10px;

            padding: 1px 0px;
            background-color: #00577c;
            color: white;
            font-size: 10px;
            font-weight: 900;
            border: none;
            border-radius: 30px;
          }

          .group {
            position: relative;
            margin-left: 10px;
            width: 10%;
            height: 30px;
            padding: 1px 0px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

            background: rgb(255, 255, 255);
            display: flex;

            border-radius: 20px;

            input,
            button {
              width: 100%;
            }

            input[type="password"],
            input[type="text"],



            input[type="date"] {
              background: none;
              color: #000000;
              font-family: 'arial';
              margin-left: 10px;
              font-weight: 600;
              width: 100px;
              margin-top: 10px;
              font-size: 12px;
              padding: 1px;
              display: block;
              border: none;

              &:focus {
                outline: none;
              }

              &:focus~label,
              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #016c11;

              }


            }

            input[type="number"] {
              background: none;
              color: #000000;
              font-family: 'arial';
              z-index: 100;
              margin-left: 10px;
              font-weight: 600;
              width: 50px;
              font-size: 12px;
              margin-top: 10px;
              padding: 1px;
              display: block;
              border: none;

              &:focus {
                outline: none;
              }

              &:focus~label,
              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #016c11;
              }

            }


            input[type="date"]:placeholder-shown {
              text-transform: uppercase;
              opacity: .3;

            }

            .iconsAdd {
              height: 35px;
              width: 20px;
              z-index: 10;

              border-radius: 20px;
              background-color: #006711;
              cursor: pointer !important;


              label {
                pointer-events: none;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                background-color: #016c11;
                color: #ffffff;
              }



            }

            label {
              position: absolute;
              pointer-events: none;
              left: 1px;
              top: -0;
              transition: 400ms ease all;
              margin-left: 15px;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 12px;
              color: #006711;
            }

            .eye {
              position: absolute;
              top: 0;
              bottom: 0;
              right: 15px;
              margin: auto;
              color: $primary-color;
            }

            .error {
              transform: none;
              position: absolute;
              bottom: -20px;
              left: 25px;
              right: 0;
            }

          }

        }
      }


    }




  }

  .controlBoard {
    position: absolute;
    right: 40%;
    top: 25%;
    flex-direction: column;
    height: 60%;
    width: 20%;
    padding: 1px 0px;
    background-color: #013235;
    display: flex;
    border-radius: 25px;

    .group {
      position: relative;
      margin: 10px;
      width: 100%auto;
      align-self: center;

      padding: 1px 0px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      background: rgb(255, 255, 255);

      border-radius: 20px;

      input[type="date"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        font-weight: 600;
        width: 90%;
        margin: 14px;

        font-size: 16px;
        padding: 1px;
        text-align: center;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          width: 90%;
          font-size: 12px;
          text-align: center;
          color: #016c11;
        }


      }



      input[type="text"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        font-weight: 600;
        width: 90%;
        margin: 14px;

        font-size: 16px;
        padding: 1px;
        text-align: center;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          width: 90%;
          font-size: 12px;
          text-align: center;
          color: #016c11;
        }


      }





      label {
        position: absolute;
        pointer-events: none;
        top: -0;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 23px;
        color: #006711;
        text-align: center;

      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -20px;
        left: 25px;
        right: 0;
      }

    }


    h6 {
      position: relative;
      height: 35px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 23px;
      color: #ffffff;
      text-align: center;

    }

    .actionsButton {
      align-items: center;
      align-self: center;
      margin: 12px;

      .reloadButton {
        height: 30px;
        width: 70px;
        position: relative;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        margin-left: 10px;

        padding: 1px 0px;
        background-color: #067c00;
        color: white;
        font-size: 12px;
        font-weight: 900;
        border: none;
        border-radius: 30px;
      }

      .issueButton {
        height: 30px;
        width: 90px;
        position: relative;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        margin-left: 10px;

        padding: 1px 0px;
        background-color: #0094a4;
        color: white;
        font-size: 12px;
        font-weight: 900;
        border: none;
        border-radius: 30px;
      }
    }



  }

  .absoluteDiv {
    position: absolute;
    right: 12%;
    top: 2%;
    flex-direction: row;
    height: 60px;
    padding: 1px 0px;
    display: flex;

    .heading {
      font-size: 30px;
      margin-bottom: 15px;
      font-weight: 600;

      align-self: center;
      text-align: center;
      color: rgb(21, 0, 83);
    }



    .formFullWidth2 {
      width: 99%;
      height: 30px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
      border-radius: 30px;

      .ant-select-selector {
        border-radius: 20px;
        cursor: pointer !important;
      }

      >div {
        height: 100%;
        border: 0;

        span {
          display: flex;
          align-items: center;
          outline: 0;
          margin: auto 15px;
          border: 0;
          -webkit-highlight: none;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;

          margin-top: 15px;

          &:focus {
            outline: 0;
            border: 0;
          }

          &:valid~label {
            top: -0;
            font-size: 12px;

            color: #016c11;

          }
        }
      }

      .error {
        height: unset;
      }
    }

    .group {
      position: relative;
      margin-left: 10px;
      width: 20%;
      height: 30px;
      padding: 1px 0px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      background: rgb(255, 255, 255);
      display: flex;

      border-radius: 20px;

      input,
      button {
        width: 100%;
      }

      input[type="password"],
      input[type="text"],



      input[type="date"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        margin-left: 10px;
        font-weight: 600;
        width: 100px;
        margin-top: 10px;
        font-size: 12px;
        padding: 1px;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #016c11;

        }


      }

      input[type="number"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        z-index: 100;
        margin-left: 10px;
        font-weight: 600;
        width: 120px;
        font-size: 12px;
        margin-top: 10px;
        padding: 1px;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #016c11;
        }

      }


      input[type="date"]:placeholder-shown {
        text-transform: uppercase;
        opacity: .3;

      }

      .iconsAdd {
        height: 35px;
        width: 20px;
        z-index: 10;

        border-radius: 20px;
        background-color: #006711;
        cursor: pointer !important;


        label {
          pointer-events: none;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          background-color: #016c11;
          color: #ffffff;
        }



      }

      label {
        position: absolute;
        pointer-events: none;
        left: 11px;
        top: -0;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #006711;
      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -20px;
        left: 25px;
        right: 0;
      }

    }

    .reloadButton {
      height: 30px;
      width: 70px;
      position: relative;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      margin-left: 10px;

      padding: 1px 0px;
      background-color: #00577c;
      color: white;
      font-size: 12px;
      font-weight: 900;
      border: none;
      border-radius: 30px;
    }
  }





  .closeButton {
    height: 30px;
    width: 70px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 12px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}