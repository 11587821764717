


.dashLayout {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    /* height: 100vh; */
  }
 