@import "../../static/scss/colors";

.uploadMultipleUserCsv {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    color: #002267;
    margin: 20px 10px;
    text-transform: none;
  }
  .desc {
    font-size: 17px;
  }
  .download,
  .subtitle {
    font-size: 19px;
    font-weight: 500;
  }
  .subtitle {
    margin-top: 22px;
  }
  .btns {
    display: flex;
    width: 100%;
  }
  .btnClear {
    margin: 16px auto;
    flex: 0 0 40%;
    height: 55px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #adadad;
    background: #d9d9d9;
  }
  .formButtonWidth {
    margin: 16px auto;
    flex: 0 0 40%;
    height: 55px;
    border-radius: 4px;
    > button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      border: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      background: linear-gradient(to right, #002267, #ff7e3d);
      svg {
        margin-right: 10px;
        height: 50%;
        width: auto;
      }
    }
  }
}
.messageLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  text-transform: none;
  color: #000000;
  margin: 30px 0px;
}
.message {
  text-align: center;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: #117d00;
  margin: 25px 0px;
}
.error{
  color: red !important;
}
.enterCsv {
  margin-right: 20%;
  overflow: hidden;
  display: flex;
  position: relative;
  margin: 10px 0;
  flex: 0 0 100%;
  height: 56px;
  box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  margin: 35px 6px;
  input {
    display: none;
  }
  .label {
    background: white;
    flex: 1;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    font-size: 15px;
  }
  .btnBrowse {
    flex: 0 0 25%;
    background: #d9d9d9;
    opacity: 0.63;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 11px;
    border-radius: 30px;
    color: #adadad;
    margin: 9px;
    cursor: pointer;
  }
  > div {
    &:first-of-type {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
