.form-container {
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background: linear-gradient(45deg, #20107e, #9c57b3);
  /* Soft Purple Gradient */
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.form-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  color: #280530;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 1px 3px #000000;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #DABFFF;
  /* Soft Violet Border */
  border-radius: 4px;
  background-color: #ffffff;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-item {
  flex: 1;
}

/* button {
  padding: 12px 30px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50px;
  border: none;
  margin-top: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
} */

/* button[type="button"] {
  background: linear-gradient(45deg, #5B9FFF, #82B1FF);
  color: #ffffff;
}

button[type="button"]:hover {
  background: linear-gradient(45deg, #6FAFFF, #92C8FF);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}

button[type="submit"] {
  background: linear-gradient(45deg, #03d12c, #047808);
  color: #ffffff;
}

button[type="submit"]:hover {
  background: linear-gradient(45deg, #21c04b, #074912);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
} */

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-item {
  flex: 1;
  background: rgba(255, 255, 255, 0.5);
  /* 50% Transparent White */
  padding: 10px;
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

.checkbox-item:hover {
  opacity: 0.7;
}

.form-label {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 1px 3px #000000;
  margin-bottom: 5px;
  cursor: pointer;
}

.header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.header-info span {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.header-info button {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.header-info button:hover {
  background-color: #0056b3;
}

.name-email {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.department-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.department-item {
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 80px;
}

.department-time {
  margin-bottom: 20px;
}

.lunch-break {
  font-style: italic;
}


.department-time {
  display: flex;
  align-items: center;
  margin: 10px 0;
  border: 2px solid #dadde9;
  border-radius: 10px;
  padding: 10px;
}

.department-time h2 {
  flex: 1;
  color: #dce3ff;
}

.department-time select {
  flex: 1;
  padding: 10px;
  border: 1px solid #d44a00;
  border-radius: 5px;
}

/* Global Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Typography */
h1,
h2,
h3,
p {
  margin: 0;
  color: #333;
  /* Adjust as per your color scheme */
}

/* Form Elements */
input,
select,



.image-container {
  width: 100%;
  /* Adjust as needed */
  display: flex;
  justify-content: center;
  /* Centers the image horizontally */
  margin-bottom: 20px;
  /* Spacing between the image and the form */
}

.form-image {
  max-width: 100%;
  /* Ensure the image is not wider than the container */
  height: auto;
  /* Maintain aspect ratio */
  border-radius: 10px;
  /* Optional: Adds rounded corners to the image */
}

/* Utility Classes */
.text-center {
  text-align: center;
}


.update-card {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #007b3d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.update-text {
  font-weight: bold;
  color: white;
}

.update-date {
  font-style: italic;
  color: white;
}