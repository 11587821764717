.tableWrap {
  height: 0;
  flex: 1;
  overflow: scroll;
  position: relative;
  border-radius: 0px 0px 15px 15px;
  font-weight: 500;
  .userTable {
    color: #002267;
    min-width: 100%;
    border-collapse: collapse;
    .ant-table {
      height: 100vh !important;
    }
    th {
      height: 100%;
      border: 0px none transparent;
      padding: 0;
    }
    tr {
      th,
      td {
        &:first-of-type {
          width: 80px;
          // max-width: 80px;
          min-width: 80px;
        }
      }
    }
    thead {
      tr {
        background: #f8f8fd;
        height: 14px;

        th {
          position: relative;
          background: #ffffff !important;
          font-weight: bold;
          color: #002267;
          position: sticky;
          z-index: 3;
          top: 0;
          &:first-of-type,
          &:nth-of-type(2) {
            z-index: 10;
          }
        }
      }
    }
    tbody {
      .tik {
        height: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    tr {
      height: 50px;
      border-bottom: 1px solid rgb(70, 70, 70);
      th {
        color: white;
        font-weight: 800;
        white-space: nowrap;
        padding: 10px 15px !important;
        text-align: left;
        font-size: 14px;
        line-height: 24px;
      }
      td {
        background: white;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;
        padding: 0 20px;
        text-align: left;
        font-size: 14px;
        color: #002267;
      }
      th {
        background: white;
        color: rgb(212, 16, 16);
        &:first-of-type {
          width: 85px;
          left: 0;
        }
        &:nth-of-type(2) {
          left: 80px;
        }
        &:first-of-type,
        &:nth-of-type(2) {
          position: sticky;
          z-index: 2;
        }
      }
    }
    .status_tag {
      font-size: 14px;
    }

    .active_status {
      color: #228609;
    }
    .delete_status {
      color: rgb(255, 115, 0);
    }
    .suspend_status {
      background-color: rgb(255, 0, 0);
    }
  }
  // &[data-table="top"],
  // &[data-table="both"] {
  //   thead {
  //     th {
  //       position: -webkit-sticky;
  //       position: sticky;
  //       top: 0;
  //     }
  //   }
  // }
  // &[data-table="left"],
  // &[data-table="both"] {
  //   tbody {
  //     th {
  //       position: -webkit-sticky;
  //       position: sticky;
  //       left: 0;
  //       z-index: 1;
  //     }
  //   }

  //   thead {
  //     th {
  //       position: -webkit-sticky;
  //       position: sticky;
  //       left: 0;
  //       z-index: 2;
  //     }
  //   }
  // }
}
