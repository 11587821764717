.deleteMain{
    height: 17rem;
    width: 45vw;
    border-radius: 30px;
    box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
   position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    text-align: center;
    h1{
       
        font-weight: bold;
        color: white;
    }
    h3{
        font-weight: 600;
        font-size: 30px;
    }
    button{
        height: 50px;
        padding: 10px 20px;
        margin: 10px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        border: solid 1px;
        font-weight: 800;
        font-size: 20px;
        color: red;
        
    }
    .cancelBtn::before{
        content: "";
        height: 50px;
        background-color: rgba(66, 66, 241, 0.658);
        width: 0;
        top: 0;
        left: 0;
        position: absolute;
        transform:width 5s ;
        z-index: 1;
       
    }
    .deleteBtn::before{
        content: "";
        height: 70px;
        
        background-color: rgb(156, 21, 21);
        width: 0;
        top: 0;
        left: 0;
        position: absolute;
        
        
    }
    .cancelBtn:hover::before ,.deleteBtn:hover::before{
        animation: animateBefore .3s linear ;
        width: 50%;
       } 
    .cancelBtn{
        color: royalblue;
    }    
    .deleteBtn{color: red;}
    
    

}

@keyframes animateBefore{
    0%{width: 0%;}
   
    100%{width:50%}
}

.suspendMain{
    z-index: 111;
    height: 40rem;
    box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
    width: 50%;
    position: fixed;
    top: 50%;
    left: 35%;
    transform: translate(-50%,-50%);
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
   
    padding: 10px;
    transition: all 1s ease;
    textarea{
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        width: 90%;
        border-radius: 10px;
    }

    
    
    div{
        display: flex;
        justify-content: space-between;
        button{
            border: none;
            box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
            border-radius: 60px;
            font-size: 20px;
            font-weight: 600;
            margin: 20px;
        }
        .cancelBtn{
            background-color: #a1a1a1;
            width: 4cm;
         }
         .suspendBtn{
             color: #ffffff;
             height: 15mm;
             width: 4cm;
             background-color: rgb(17, 109, 4);
         }
         h1{
             font-weight: 600;
         }
    }
    

    .labelDiv{
        border: none;
        padding: 20px 60px;
        display: flex;
        justify-content: left;
        label{
            font-size: 18px;
            font-weight: 700;
            margin:10px !important;
        }
        input{
            border: none;
            border-radius: 10px;
            margin-left: 10px;
        }
    }

    .failedMsg{
        position: fixed;
        z-index: 9999;
        
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: rgb(255, 255, 255);
        border: solid red 2px;
        height: 10rem;
        width: 22rem;
   
        display: block;
        border-radius: 20px;
        h1{
            font-weight: 700;
            color: red;

        }
        h3{
            font-weight: 500;
            font-size: 16px;
        }
        button{
            background-color: grey;
        }
    }
    .loading{
        height: 5rem;
        margin: 10% 50%;
        

    }
}

.tranferMain{
    height: 30rem;
    width: 40vw;
    box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    border-radius: 20px;
    left: 35%;
    transform: translate(-50%,-50%);
    z-index: 111;
    
    font-weight: 700;
    text-align: center;
    
    
    h1{
        color: rgb(11, 12, 83) !important;
        margin: 20px 0;
    }
    .Dropdown{
        height: 68px;
        width: 17rem;
        font-size: 50px;
        font-weight: 700;
        margin: 10px auto;
        border-radius: 10px;
        overflow: hidden;
    }
    .btns{
        height: 50px;
        width: fit-content;
        margin: 70px auto;
        button{
            height: 50px;
            padding: 10px;
            margin: 0 10px;
            font-weight: 700;
            color: #ffffff;
        }
        .tranferBtn{
            background-color: rgb(8, 100, 31);
        }

    }
    .failedMsg{
        position: fixed;
        z-index: 9999;
        
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: rgb(255, 255, 255);
        border: solid red 2px;
        height: 10rem;
        width: 22rem;
   
        display: block;
        border-radius: 20px;
        h2{
            font-weight: 700;
            color: red;

        }
        h3{
            font-weight: 500;
            font-size: 16px;
        }
        button{
            background-color: grey;
        }
    }
}