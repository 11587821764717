@import "../../../../../static/scss/colors";

.feeForm {
  width: 90%;
  margin: 4%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;











  .group {
    position: relative;

    background: rgb(255, 255, 255);
    filter: drop-shadow(0px 4px 9px rgb(0 0 0 / 10%));
    margin-bottom: 1px;
    display: flex;
    border-radius: 60px;

    input[type="text"] {
      background: none;
      color: #000000;
      font-family: 'Arial';
      margin-left: 30px;
      margin-top: 10px;
      font-weight: 900;
      width: 70%;
      height: 90%;
      font-size: 38px;
      padding: 10px;
      display: block;
      border-radius: 60px;
      border: none;


      &:valid~label {
        top: 4px;
        font-size: 17px;
        font-weight: 900;
        font-family: 'Arial';
        color: #0c5d10;
      }
    }

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    input[type="date"]:placeholder-shown {
      text-transform: uppercase;
    }

    label {
      position: absolute;
      pointer-events: none;
      left: 11px;
      top: 10px;
      transition: 400ms ease all;
      margin-left: 15px;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 23px;
      color: #c0c0c0;
    }

    .eye {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
      color: $primary-color;
    }

    .error {
      transform: none;
      position: absolute;
      bottom: -20px;
      left: 25px;
      right: 0;
    }
  }
}