.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 790;
  position: absolute;

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .modal {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;
    overflow: hidden;


    .listContainer2 {
      flex-direction: column;
      padding: 20px;

      margin-top: 38px;







    }

    .tableView {
      flex: 0 0 98%;
      margin-right: 20px;
      margin: 10px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      border-radius: 25px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

      .tableHeader {
        background-color: rgb(0, 34, 103);
        display: flex;
        // margin: 1px 0;
        border-radius: 25px 25px 0px 0px;

        h2 {
          color: #fff;
          flex: 2;
          font-size: 2rem;
          font-weight: 700;
          margin: 10px 38px;
        }
      }

      .tableWrap {
        height: 0;
        flex: 1;
        overflow: scroll;
        position: relative;
        border-radius: 0px 0px 25px 25px;
        font-weight: 500;

        .userTable {
          color: #002267;
          min-width: 100%;
          border-collapse: collapse;

          th {
            height: 100%;
            border: 0px none transparent;
            padding: 0;
          }

          tr {

            th,
            td {
              &:first-of-type {
                width: 80px;
                // max-width: 80px;
                min-width: 80px;
              }
            }
          }

          thead {
            tr {
              background: #f8f8fd;
              height: 35px;

              th {
                position: relative;
                background: #ffffff;
                font-weight: bold;
                color: #002267;
                position: sticky;
                z-index: 3;
                top: 0;

                &:first-of-type,
                &:nth-of-type(2) {
                  z-index: 10;
                }
              }
            }
          }

          tbody {
            .tik {
              height: 49px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          tr {
            height: 50px;
            border-bottom: 1px solid rgb(70, 70, 70);

            th {
              color: white;
              font-weight: 800;
              white-space: nowrap;
              padding: 15px 15px !important;
              text-align: left;
              font-size: 20px;
              line-height: 24px;
            }

            td {
              background: white;
              font-weight: 500;
              line-height: 22px;
              white-space: nowrap;
              padding: 0 20px;
              text-align: left;
              font-size: 18px;
              color: #002267;
            }

            th {
              background: white;
              color: rgb(212, 16, 16);

              &:first-of-type {
                width: 85px;
                left: 0;
              }

              &:nth-of-type(2) {
                left: 80px;
              }

              &:first-of-type,
              &:nth-of-type(2) {
                position: sticky;
                z-index: 2;
              }
            }
          }

          .status_tag {
            font-size: 18px;
          }

          .active_status {
            color: #228609;
          }

          .filling {
            color: #06b6dd;
          }

          .delete_status,
          .pending_status {
            color: rgb(255, 115, 0);
          }

          .notFilled_status {
            color: #fc0000;
          }

          .suspend_status {
            background-color: rgb(255, 0, 0);
          }
        }
      }
    }





  }

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .reloadButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 6.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #044183;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }
}