.roleCards {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
    aspect-ratio: 1/1;
    transition: 0.5s;
    width: 17.25vh;

    height: 17.25vh;
}




.roleCards .icons {
    border: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 1vw;
    align-items: center;
    transition: 0.5s;
    width: 60%;
    height: 70%;

}
.roleCards h2 {
    font-size: 1.5vh;
    color: rgba(255, 255, 255, 0.943);
    font-weight: 500;
    width: 17.25vh;

}

.mainContainer {
    position: relative;
    background: rgba(0, 0, 0, 0.78);
    backdrop-filter: blur(20%);
}


.roleCards:hover {
    border-radius: 5%;
    transform: translateY(-8px);
}





@keyframes float {
    0% {
        transform: translatey(0);
    }

    50% {
        transform: translatey(-2px);
    }

    100% {
        margin-bottom: 0;
    }
}

.roleCards img {
  
    aspect-ratio: 1/1;
    object-fit: contain;

}

.contain h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    color: rgb(37, 30, 30);
    text-transform: capitalize;
    text-decoration: dashed;
}

.sideBar{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 5px;
    width: 15vw;
    position: relative;
    height: 100%;
    padding: 1vw;
    border-radius: 0vw 1vw 1vw 0vw;

    background: rgba(70, 69, 69, 0.334);
    
}

.sideBar .icons{
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 150px;
    height: 3vw;
    background: #0000005f !important;

}

.sideBar .icons span{
    padding-left: 10px;
    font-size: 1vw;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 3px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.800);
    width: 70%;
}
.sideBar .icons img{
    width: 16%;
    margin-left: 6%;
    aspect-ratio: 1/1;
    object-fit: contain;
}
.subContainer{
    height: 100%;
    display: flex;
}

.collegeIcon{
    width: 70% !important;
    border-radius: 10px !important;
    align-self: center;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.components{
    position: relative;
    width: 100%;
}
.posterContainer{
    position: relative;
    top: 0;
    padding: 10px;
    display: flex;
    overflow-y: auto;
    justify-content: start;
    flex-direction: column;
    width: 25%;
    height: 100%;
}
.text-shadow {
    text-shadow: 2px 2px 2px #000000; /* Horizontal offset, Vertical offset, Blur radius, Color */
}
.border-custom{
    border:1px solid rgb(52 211 153);
}
.blur-custom{
    backdrop-filter: blur(5px);
}

.a::placeholder::first-letter{
    font-size: 100px;
}







