.editBtn {
    background: #0D6EFD;
    border: none;
    border-radius: 3px;
    height: auto;
    color: #fcf2f2;
    width: 40px;
    cursor: pointer;
    width: auto;
}

.editBtn:hover {
    animation: colorChange 0.7s;
    background: #063275;
}

@keyframes colorChange {
    0% {
        background: #0D6EFD;
    }

    100% {
        background: #063275;
    }
}

.aproveBtn {
    background: red;
    border: none;
    border-radius: 3px;
    height: auto;
    color: #fcf2f2;
    cursor: pointer;
    width: auto;
}

.none {
    display: none;
}

.tcForm {
    background: #5bc0de;
    border: none;
    height: 4vh;
    margin: 5px;
    cursor: pointer;
    color: white;
    font-weight: bolder;
    border-radius: 6px;
}

.tcForm:hover {
    animation: TC 0.7s;
    background-color: #287188;
}

@keyframes TC {
    0% {
        background-color: #5bc0de;
    }

    100% {
        background-color: #287188;
    }
}

.inputBox {
    border-radius: 5px;
    border: solid 0.1px;
}

.letter {
    height: 14vh;
    margin-top: 10px;
    width: 30vh;
    border: solid 0.1px;
}

.instTc {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border: solid 1px black;
    position: absolute;
    animation: getin 0.5s;
    left: 0px;
    opacity: 1;
    top: 0px;
    margin: auto;
    right: 0px;
    border-radius: 25px;
    width: 90%;
    height: 90%;
    bottom: 0px;
    z-index: 100;
    position: absolute;
    padding: 15px;
}


.dropdown {
    position: relative;
}

.dropdown ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f2f2f2;
}

.inputBox1 {
    border-radius: 5px;
    border: solid 0.1px;
    width: 28vh;
    cursor: pointer;
    color: black;
}

.newScreen {
    position: fixed;
    height: 70vh;
    width: 70vh;
    background: #ccc;
}

.instTc1 {
    margin: auto;
    width: 80%;
    margin-top: 3%;
    background: #ccc;
    border-radius: 12px;
    padding: 15px;
    height: 100vh;
    width: 100vh;
}

.letter1 {
    height: 20vh;
    margin-top: 10px;
    width: 30vh;
    border: solid 0.1px;
}

@keyframes getin {
    0% {
        margin-top: 100%;
    }

    100% {
        margin-top: 10vh;
    }
}

.closes {
    color: #f2f2f2;
    background-color: #DC4C64;
    border-radius: 100px;
    height: 35px;
    width: 35px;
    position: fixed;
    top: 45px;
    right: 90px;
    z-index: 9999;
    border: none;
    cursor: pointer;

}

.closes:hover {
    height: 40px;
    border: solid 1px red;
    animation: closes .3s;
    width: 40px;
}

@keyframes closes {
    0% {
        height: 35px;
        width: 35px;
    }

    100% {
        height: 40px;
        width: 40px;
    }
}

.instTc label {
    margin-top: 10px;
}

.cover {
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    background-color: rgb(51, 51, 51);
    position: absolute;
    display: flex;
    flex: 1;
    z-index: 100;
}

.submitBtn {
    background: #0D6EFD;
    border: none;
    border-radius: 3px;
    height: auto;
    color: #fcf2f2;
    width: 140px;
    cursor: pointer;
    margin: auto;
}

.editTc {
    border: none;
    width: 140px;
    cursor: pointer;
    margin: auto;

}

.submitBtn:hover {
    animation: colorChange 0.7s;
    background: #063275;
}
.animation-opacity{
    animation:opacity 1s ;
    height: 30px;

}

@keyframes opacity {
    0%{
        opacity: 0;
        height: 0px;
    }
    100%{
        opacity: 1;
        height: 30px;
    }
}

.centerContent{
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 40px;
}