.department-container {
    margin: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    min-width: 700px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    max-height: 95%;
    overflow-y: auto;
}

.department-container:hover {
    transform: translateY(-5px);
}

.department-name {
    color: #07164a;
    margin-bottom: 15px;
}

.subject-details {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.subject-heading {
    color: #d44a00;
    margin-bottom: 10px;
}

.subject-list {
    list-style: none;
    padding: 0;
}

.subject-item {
    background-color: #eef2f7;
    color: #333;
    margin: 5px 0;
    padding: 8px;
    border-radius: 5px;
    justify-content: space-between;
}

.subject-name {
    width: '70%';
    text-align: left;
}

.subject-code {
    font-weight: bold;
    width: '20%';
    text-align: left;
}

.subject-year {
    font-weight: bold;
    width: '10%';
    text-align: left;
}

.custom-scrollbar {
    overflow-x: auto;
    /* Enable horizontal scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 100%;
    /* Set a fixed max-height */
    max-width: 100%;
    /* Optional: Set a max-width if necessary */

    scrollbar-width: thin;
    /* For Firefox */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* For IE and Edge */

    /* Styles for WebKit browsers */
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        /* Added height for horizontal scrollbar */
    }

    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
        border: 3px solid #f0f0f0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    /* Styles for horizontal scrollbar */
    &::-webkit-scrollbar-track-horizontal {
        background-color: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb-horizontal {
        background-color: #888;
        border-radius: 6px;
        border: 3px solid #f0f0f0;
    }

    &::-webkit-scrollbar-thumb-horizontal:hover {
        background-color: #555;
    }
}