@import "../../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;

  right: 0px;
  bottom: 0px;
  z-index: 790;
  position: absolute;





  .modal {
    display: flex;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;
    overflow: hidden;


    .keyValues {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background-color: rgb(4, 72, 14);
      border-radius: 20px;
      overflow-y: scroll;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

      .heading {
        font-size: 12px;
        font-weight: 600;
        color: rgb(255, 255, 255);
      }
    }


    .listContainer2 {
      width: 100%;
      flex-direction: column;
      display: flex;
      padding: 20px;
      margin-top: 38px;

      .notice {
        font-size: 40px;
        margin-bottom: 26px;
        font-weight: 400;
        text-align: center;
        width: 80%;
        align-self: center;
        color: rgb(0, 124, 8);
      }




    }





  }

  .reloadButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 7.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #0f6901;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }



  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}