@import "../../../../static/scss/colors";



.addUserForm {
  height: 90%;
  width: 70%;
  background: rgb(255, 255, 255);
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .row {
    float: left;
    width: 48%;
    margin-left: 2%;
  }




  .btns {
    display: flex;
    width: 100%;
  }

  .btnClear {
    margin: 16px auto;
    flex: 0 0 40%;
    height: 55px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    border: none;
    cursor: pointer;
    font-style: bold;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    background: #888888;
  }

  .formButtonWidth {
    margin: 16px auto;
    flex: 0 0 40%;
    height: 55px;
    border-radius: 4px;

    >button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      border: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      background: #002267;

      svg {
        margin-right: 10px;
        height: 50%;
        width: auto;
      }
    }
  }

  .head {

    font-size: 32px;
    font-weight: 600;
    text-align: center;
    height: 60px;
    background: rgb(6, 5, 66);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .textHead {
      width: 90%;

    }

    .closeHead {
      cursor: pointer;
      width: 1%;
    }

    .btnClear {
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      border: none;
      font-style: bold;
      font-weight: 600;
      font-size: 28px;
      line-height: 26px;
      color: #ffffff;
      background: #e60000;
    }
  }



  .title {
    width: 100%;
    font-weight: 600;
    min-height: 5rem;
    text-align: center;
    font-family: 'Arial';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: #002267;
    padding: 30px 0px 10px;
    border-bottom: 0.4px solid #000000;
  }

  .headMaster {
    height: 8%;
    width: 96%;
    display: flex;
    flex-direction: row;

    .headMainDiv {
      margin-left: 2%;
      text-align: center;
      width: 100%;
      align-items: center;

      .headSubDiv {
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: column;


        .masterHead {
          font-size: 35px;
          font-weight: 500;
          color: rgb(1, 8, 87);
          font-weight: 600;
          text-align: center;
          margin-left: 1%;
          margin-top: 2%;
          font-family: 'arial';
        }
      }
    }
  }


  .masterDiv {
    height: 85%;
    display: flex;
    flex-direction: row;

    .mainDiv {

      .subDiv {
        box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 6%);
        text-align: left;
        width: 96%;
        margin-top: 4%;
        margin-bottom: 4%;
        margin-left: 2%;

        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        justify-content: center;

        .subTitle {
          font-size: 17px;
          font-weight: 500;
          color: rgb(87, 87, 87);
          // display: flex;
          margin-left: 1%;
          margin-top: 2%;
          font-family: 'arial';
        }

        .subMatter {
          font-size: 20px;
          font-weight: 600;
          color: rgb(11, 1, 68);
          // display: flex;
          margin-left: 4%;
          margin-top: -2%;
          font-family: 'arial';
          width: 23;
        }



      }

      .circulationDiv {
        flex-direction: row;
        border-color: #0f0043;
        border-width: 27px;
        background: #FFFFFF;

        box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 6%);
        text-align: left;
        width: 96%;
        margin-top: 4%;
        margin-bottom: 4%;
        margin-left: 2%;
        display: flex;
        border-radius: 20px;


        .circulationSubDiv {
          text-align: left;
          width: 53%;
          margin-left: 2%;
          display: flex;
          flex-direction: column;
          border-radius: 8%;
          justify-content: center;


          .circulationSubTitle {
            font-size: 22px;
            font-weight: 600;
            color: rgb(11, 1, 68);
            // display: flex;
            margin-top: 1%;
            font-family: 'arial';
            width: 23;

          }

          .circulationSubMatter {

            font-size: 18px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            margin-top: -2%;
            font-family: 'arial';

          }
        }

        .usage {
          width: 24%;
          border-radius: 8%;
          margin: 2%;

          .mainBox {
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 10%);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .topBox {
              width: 100%;
              height: 40px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              background: #ce4f00;

              .topTex {
                height: 28px;
                width: 100%;
                justify-content: center;
                align-items: center;
                font-family: 'arial';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 30px;
                text-align: center;
                margin: 2%;
                color: #FFFFFF;
              }


            }


            .bottomTex {
              margin-top: 5%;
              justify-content: center;
              align-items: center;
              font-family: 'arial';
              font-style: normal;
              font-weight: 400;
              font-size: 30px;
              line-height: 30px;
              width: 100%;
              text-align: center;

              color: #0f0043;
            }



          }

        }

      }

      .activecirculationDiv {
        flex-direction: row;
        border-color: #0f0043;
        border-width: 27px;
        background: #94fda5;

        box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 6%);
        text-align: left;
        width: 96%;
        margin-top: 4%;
        margin-bottom: 4%;
        margin-left: 2%;
        display: flex;
        border-radius: 20px;


        .circulationSubDiv {
          text-align: left;
          width: 53%;
          margin-left: 2%;
          display: flex;
          flex-direction: column;
          border-radius: 8%;
          justify-content: center;


          .circulationSubTitle {
            font-size: 22px;
            font-weight: 600;
            color: rgb(11, 1, 68);
            // display: flex;
            margin-top: 1%;
            font-family: 'arial';
            width: 23;

          }

          .circulationSubMatter {

            font-size: 18px;
            font-weight: 500;
            color: rgb(51, 51, 51);
            margin-top: -2%;
            font-family: 'arial';

          }
        }

        .usage {
          width: 24%;
          border-radius: 8%;
          margin: 2%;

          .mainBox {
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 10%);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .topBox {
              width: 100%;
              height: 40px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              background: #ce4f00;

              .topTex {
                height: 28px;
                width: 100%;
                justify-content: center;
                align-items: center;
                font-family: 'arial';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 30px;
                text-align: center;
                margin: 2%;
                color: #FFFFFF;
              }


            }


            .bottomTex {
              margin-top: 5%;
              justify-content: center;
              align-items: center;
              font-family: 'arial';
              font-style: normal;
              font-weight: 400;
              font-size: 30px;
              line-height: 30px;
              width: 100%;
              text-align: center;

              color: #0f0043;
            }



          }

        }

        .usage {
          width: 24%;
          border-radius: 8%;
          margin: 2%;

          .mainBox {
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 1px 4px 12px 3px rgb(0 0 0 / 10%);
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .topBox {
              width: 100%;
              height: 40px;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              background: #ce4f00;

              .topTex {
                height: 28px;
                width: 100%;
                justify-content: center;
                align-items: center;
                font-family: 'arial';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 30px;
                text-align: center;
                margin: 2%;
                color: #FFFFFF;
              }


            }


            .bottomTex {
              margin-top: 5%;
              justify-content: center;
              align-items: center;
              font-family: 'arial';
              font-style: normal;
              font-weight: 400;
              font-size: 30px;
              line-height: 30px;
              width: 100%;
              text-align: center;

              color: #0f0043;
            }



          }

        }
      }
    }
  }



  .bottomhead {

    font-size: 22px;
    font-weight: 600;
    text-align: center;
    height: 60px;
    background: rgb(0, 73, 7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }



}