.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  flex-direction: row;
  bottom: 0px;
  z-index: 790;
  position: absolute;

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .modal {
    display: flex;
    height: 97%;
    width: 60%;
    flex-direction: column;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;
    overflow: hidden;


    .titleContainer {

      display: flex;
      height: 97%;
      width: 30%;
      align-self: center;
      position: relative;
      flex-direction: row;










      .image {
        margin: 5px;
        width: 100%;
        height: 100%;

        .avatar {
          height: 90%;
          width: 80%;
          align-self: center;
          border-radius: 10%;

          .dp {
            height: 98%;
            width: 100%;
            align-self: center;
            border-radius: 10%;
          }
        }

      }





    }

    .typoContainer {
      align-self: center;

      .userName {
        color: #002267;
        margin: 0;
        font-weight: 900;
        font-size: 40px;
      }
    }

    .dataWraper {
      width: 30%;
      overflow: scroll;
    }

    .percentage-loader {
      width: 200px;
      height: 20px;
      background-color: #f2f2f2;
      border-radius: 10px;
      overflow: hidden;
    }

    .progress-bar {
      height: 100%;
      background-color: #007bff;
      transition: width 0.5s ease-in-out;
    }

    .progress-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-weight: bold;
    }

    .subTitle {
      font-size: 18px;
      font-weight: 600;
      padding: 0 20px;
      margin-top: 20px;
      color: #002366;
    }

    .detailItem {
      font-weight: 500;
      font-size: 16px;
      padding: 1px 40px;
      display: flex;
      align-items: center;

      .label {
        flex: 0 0 45%;
      }

      .value {
        margin-left: 10px;
      }

      svg {
        margin-left: 6px;
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }
  }
}