

  
.loader {
    background:
     repeating-linear-gradient(135deg,var(--first-Color) 0 10px,var(--second-color) 0 20px) 0/0%   no-repeat,
     repeating-linear-gradient(135deg,#ddd    0 10px,#eee    0 20px) 0/100%;
    animation: l3 2s infinite;
  }
  @keyframes l3 {
      100% {background-size:100%}
  }



  