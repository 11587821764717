@import "../../../../static/scss/colors";

.addBookModal {
  height: 95%;
  width: 40%;
  background: white;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .head {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    background: rgb(0, 66, 77);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    height: 40px;
    width: 10%;
    position: absolute;
    right: 3%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #910e0e;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .add {
    height: 40px;
    width: 20%;
    position: relative;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    padding: 1px 0px;
    align-self: center;

    background-color: #007c25;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .delete {
    height: 40px;
    width: 10%;
    position: absolute;
    left: 3%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #ff0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .load {
    height: 40px;
    width: 20%;
    position: absolute;
    right: 23%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
    align-self: right;
    padding: 1px 0px;
    background-color: #189f01;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
    cursor: pointer
  }

  .content {
    height: 0;
    flex: 1;
    margin: 10px 0;



    .titlesSub {
      flex-direction: column;
      width: 700px;
      background-color: #ffc1c1;
      margin: 10px;
      border-radius: 20px;
      align-content: center;
      align-items: center;





      .add {
        height: 40px;
        width: 50%;
        position: relative;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        margin-left: 10px;
        padding: 1px 0px;
        margin-top: 20%;
        margin-left: 5%;
        background-color: #007c25;
        color: white;
        font-size: 16px;
        font-weight: 900;
        border: none;
        border-radius: 30px;
        cursor: pointer
      }


    }


  }
}