@import "../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: 'visible';
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;





  .modal {
    display: flex;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;


    .listContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      padding: 20px;
      border: solid;
      border-right-color: rgb(170, 122, 122);
      border-width: 0px 1px 0px 0px;



      .list {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background-color: rgb(255, 175, 175);
        border-radius: 20px;
        overflow-y: scroll;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

        label: {
          font-size: 20;
        }

        .heading {
          font-size: 20px;
          margin-bottom: 0px;
          text-align: center;
          font-weight: 900;
          color: rgb(160, 5, 5);
        }


        .submitBotton {
          height: 40px;
          width: 100px;
          position: relative;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          margin: 10px;
          right: 1.9%;
          top: 2%;
          padding: 1px 0px;
          background-color: #005f1b;
          color: white;
          font-size: 16px;
          font-weight: 900;
          border: none;
          border-radius: 20px;
        }

        .cancelBotton {
          height: 40px;
          width: 100px;
          position: relative;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
          margin: 10px;
          right: 1.9%;
          top: 2%;
          padding: 1px 0px;
          background-color: #005f1b;
          color: white;
          font-size: 16px;
          font-weight: 900;
          border: none;
          border-radius: 20px;
        }














      }








    }


    .listContainer2 {
      width: 77%;
      flex-direction: column;
      padding: 20px;




    }


  }




  .reloadButton {
    height: 40px;
    width: 100px;
    position: absolute;
    right: 17.2%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    top: 2%;
    padding: 1px 0px;
    background-color: #00577c;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
  }


  .printBotton {
    height: 40px;
    width: 100px;
    position: absolute;
    right: 9.5%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    top: 2%;
    padding: 1px 0px;
    background-color: #00577c;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 30px;
  }

  .button {
    height: 40px;
    width: 150px;

    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

margin: 1%;
    background-color: #00fd43;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}