@import "../../../static/scss/colors";
@import "../../../static/scss/mixin";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 790;
  position: absolute;

  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

  .modal {
    display: flex;
    flex-direction: column;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;
    overflow: hidden;

    .addUserScreen {
      background-color: white;

      .userTableData {
        display: flex;
        flex-direction: column;

      }
    }
  }
}