.collectFeeModule {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .list {
        width: 100%;
        height: 65%;
        overflow: auto;
        align-self: center;
        align-content: center;
        text-align: center;

        .subList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: auto;
            grid-gap: 2px;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px 3px rgb(0 0 0 / 8%);
            border-radius: 30px;
            margin: 12px;

        }

        .listItem {
            box-shadow: 0px 1px 5px 3px rgb(0 0 0 / 8%);
            padding: 5px;
            border-radius: 30px;
            color: #898989;
            text-align: center;
            cursor: pointer;
            width: 90%;
            margin: 4px;

            .optionBox {
                display: flex;
                flex-direction: column;
                width: 100%;

                p {
                    margin: auto 15px;
                    font-size: 12px;
                    color: #002267;
                    font-weight: 500;

                }

                span {
                    background-color: #17002221;
                    font-size: 22px;
                    padding: 5px 35px;
                    border-radius: 25px;
                    color: #002267;
                    font-weight: 700;
                }
            }
        }
    }

    .outerDiv {
        width: 100%;

        .listItem {
            box-shadow: 0px 1px 5px 3px rgb(0 0 0 / 8%);
            padding: 10px;
            margin: 15px auto;
            border-radius: 30px;
            color: #898989;
            text-align: left;
            cursor: pointer;
            width: 90%;

            .optionBox {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;

                p {
                    margin: auto 25px;
                    color: #002267;
                    margin: auto 15px;
                    font-size: 22px;
                    color: #002267;
                    font-weight: 700;
                }

                span {
                    background-color: #002267;
                    font-size: 30px;
                    padding: 5px 35px;
                    border-radius: 25px;
                    color: #fff;
                    font-weight: 700;
                }
            }
        }
    }
}