@import "../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;





  .modal {
    display: flex;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;





    .listContainer2 {
      width: 75%;
      flex-direction: column;
      padding: 20px;

      margin-top: 38px;

      .title {
        flex-direction: row;
        display: flex;


        .heading {
          font-size: 30px;
          width: 60%;
          margin-bottom: 1px;
          font-weight: 900;
          align-self: center;
          text-align: center;
          color: rgb(21, 0, 83);
        }



        .subDiv {
          width: 20%;

          .closeButton {
            height: 50px;
            width: 180px;
            padding: 1px 0px;
            background-color: #fd0000;
            color: white;
            font-size: 13px;
            font-weight: 900;
            border: none;
            border-radius: 20px;
          }
        }

      }

      .userTable {
        color: #ffffff;
        min-width: 100%;
        border-collapse: collapse;

        th {
          height: 100%;
          padding: 0;
        }

        tr {

          th,
          td {
            &:first-of-type {
              width: 80px;
              // max-width: 80px;
              min-width: 80px;
            }
          }
        }

        thead {
          tr {
            background: #ffffff;
            height: 35px;


            th {
              position: relative;
              background: #110243;
              font-weight: bold;
              color: #ffffff;
              position: sticky;
              z-index: 3;
              top: 0;

              &:first-of-type,
              &:nth-of-type(2) {
                z-index: 10;
              }
            }
          }
        }

        tbody {
          .tik {
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        tr {
          height: 50px;
          border-bottom: 1px solid rgb(70, 70, 70);

          th {
            color: white;
            font-weight: 400;
            white-space: nowrap;
            padding: 0 20px;
            text-align: left;
          }

          td {
            background: white;
            font-weight: 900;
            font-size: 14px;
            font-family: 'arial';
            white-space: nowrap;
            padding: 0 24px;
            text-align: left;
          }

          th {
            background: white;
            color: rgb(212, 16, 16);

            &:first-of-type {
              width: 85px;
              left: 0;
            }

            &:nth-of-type(2) {
              left: 80px;
            }

            &:first-of-type,
            &:nth-of-type(2) {
              position: sticky;
              z-index: 2;
            }
          }
        }


      }



    }


    .listContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23%;
      margin-top: 42px;






      .denominations {
        display: flex;
        flex-direction: column;
        height: 97%;
        width: 100%;
        background-color: rgb(4, 72, 14);
        border-radius: 20px;
        overflow-y: scroll;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

        .heading {
          font-size: 20px;
          margin-bottom: 0px;
          text-align: center;
          font-weight: 900;
          color: rgb(255, 255, 255);
        }


        .subDiv {
          flex-direction: row;
          margin-top: 0px;
          align-self: center;
          width: 90%;

          display: flex;

          .subHeads {
            font-size: 16px;
            width: 150px;
            font-weight: 600;
            align-self: right;
            color: rgb(255, 255, 255);
          }

          .sub {
            font-size: 16px;
            width: 20px;
            font-weight: 600;
            align-self: center;
            color: rgb(255, 255, 255);
          }

          .count {
            font-size: 16px;
            width: 50px;
            font-weight: 600;
            align-self: center;
            color: rgb(255, 255, 255);
          }

          .subAmounts {
            font-size: 16px;
            font-weight: 600;
            align-self: right;
            color: rgb(255, 255, 255);
          }
        }










      }







    }



  }

  .absoluteDiv {
    position: absolute;
    right: 8%;
    top: 2%;
    flex-direction: row;
    height: 60px;
    padding: 1px 0px;
    display: flex;

    .heading {
      font-size: 30px;
      margin-bottom: 15px;
      font-weight: 600;

      align-self: center;
      text-align: center;
      color: rgb(21, 0, 83);
    }


    .formFullWidth2 {
      width: 100%;
      height: 40px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
      border-radius: 30px;

      .ant-select-selector {
        border-radius: 20px;
        cursor: pointer !important;
      }

      >div {
        height: 100%;
        border: 0;

        span {
          display: flex;
          align-items: center;
          outline: 0;
          margin: auto 15px;
          border: 0;
          -webkit-highlight: none;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 23px;

          margin-top: 15px;

          &:focus {
            outline: 0;
            border: 0;
          }

          &:valid~label {
            top: -0;
            font-size: 12px;

            color: #016c11;

          }
        }
      }

      .error {
        height: unset;
      }
    }

    .group {
      position: relative;
      margin-left: 10px;
      width: 140px;
      height: 40px;
      padding: 1px 0px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      background: rgb(255, 255, 255);
      display: flex;

      border-radius: 20px;

      input,
      button {
        width: 100%;
      }

      input[type="password"],
      input[type="text"],


      input[type="date"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        margin-left: 10px;
        font-weight: 600;
        width: 120px;
        margin-top: 10px;
        font-size: 16px;
        padding: 1px;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #016c11;

        }


      }

      input[type="number"] {
        background: none;
        color: #000000;
        font-family: 'arial';
        margin-left: 10px;
        font-weight: 600;
        width: 120px;
        font-size: 16px;
        margin-top: 10px;
        padding: 1px;
        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #016c11;
        }

      }


      input[type="date"]:placeholder-shown {
        text-transform: uppercase;
        opacity: .3;

      }

      label {
        position: absolute;
        pointer-events: none;
        left: 11px;
        top: -0;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 23px;
        color: #006711;
      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -20px;
        left: 25px;
        right: 0;
      }

    }

    .reloadButton {
      height: 40px;
      width: 100px;
      position: relative;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      margin-left: 10px;

      padding: 1px 0px;
      background-color: #00577c;
      color: white;
      font-size: 16px;
      font-weight: 900;
      border: none;
      border-radius: 30px;
    }
  }





  .closeButton {
    height: 40px;
    width: 100px;
    position: absolute;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

    right: 1.9%;
    top: 2%;
    padding: 1px 0px;
    background-color: #fd0000;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border: none;
    border-radius: 20px;
  }

}