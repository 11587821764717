.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
}

.columnDisplay {
  flex-direction: column;
}

.modal {
  display: flex;
  width: 60%;
  height: 75%;
  background-color: #f4f4f4;
  border-radius: 25px;
}

.listContainer {
  display: flex;
  flex: 0.4;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: solid;
  border-right-color: black;
  border-width: 0px 1px 0px 0px;
}

.imageContainer {
  position: relative;
  display: flex;
  flex: 0.6;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  img {
    width: 45%;
    height: auto;
    margin: 0px 10px;
  }
  .fillImage {
    width: auto;
    height: 80%;
  }
  .closeButton {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .formField {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    height: 2rem;
    width: 60%;
    label {
      padding: 0px;
      margin: 0px;
      width: 100%;
    }
  }
}

.heading {
  font-size: 30px;
  margin-bottom: 26px;
  text-align: center;
  color: black;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  padding: 15px;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  label {
    padding: 0px;
    margin: 0px;
  }
  svg {
    height: 25px;
    width: 25px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: white;
  border-radius: 25px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  overflow-y: scroll;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.listItem {
  display: flex;
  height: auto !important;
  align-self: stretch;
  justify-content: center;
  margin: 15px 20px 0px;
  padding: 15px 15px !important;
  background-color: #f4f4f4 !important;
  border: none;
  border-radius: 50px !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
}

.button {
  width: 100%;
  padding: 10px 0px;
  background-color: #002267;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
}
