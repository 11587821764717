@tailwind base;
@tailwind components;
@tailwind utilities;

/* .underline{
    text-decoration: underline;
} */

:root {
  --responsive-value: calc(sqrt(100vw * 100vw + 100vh * 100vh));
  --primary-bg-color: rgba(255, 255, 255, 0.1);
  --primary-text-color: #fff;
  --secondary-bg-color: rgba(255, 255, 255, 0.3);
  --input-border-color: rgba(255, 255, 255, 0.3);
  --button-primary-bg: #4e44ce;
  --button-primary-hover-bg: #5a55e8;
  --button-secondary-bg: rgba(255, 255, 255, 0.1);
  --button-secondary-border: rgba(255, 255, 255, 0.2);
  ;
}




@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}


.loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.loader:before {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translate(-5px, -50%);
  background: linear-gradient(to right, #fff 50%, #de3500 50%) no-repeat;
  background-size: 200% auto;
  background-position: 100% 0;
  animation: colorBallMoveX 1.5s linear infinite alternate;
}

.loader:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: #de3500;
}

@keyframes colorBallMoveX {
  0% {
    background-position: 0% 0;
    transform: translate(-15px, -50%);
  }

  15%,
  25% {
    background-position: 0% 0;
    transform: translate(0px, -50%);
  }

  75%,
  85% {
    background-position: 100% 0;
    transform: translate(50px, -50%);
  }

  100% {
    background-position: 100% 0;
    transform: translate(65px, -50%);
  }
}
.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4em;
  color: rgba(184, 184, 184, 0.5); /* Light grey color with low opacity */
  z-index: -1;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
  -webkit-print-color-adjust: exact;
}

@media print {

  /* Hide non-printable elements */
  body * {
    visibility: hidden;

  }



  .watermark {
    display: block;
  }
  .page-break {
    page-break-after: always;
  }
  
  .page-break {
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
  /* Show printable content and its children */
  .printable,
  .printable * {
    visibility: visible;

  }
  .pageBreak {
    break-after: page; /* Ensures a page break after this element when printing */
  }
  /* Position and size printable content */
  .printable {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px;
    width: 100%;
    height: auto;
    /* Allow content to expand as needed */
    overflow: visible;

  }

  .single-break {
    page-break-after: always;
  }

  .printable * {
    page-break-inside: auto;
    break-inside: avoid-column;
  }
  .break-after-auto {
    page-break-after: auto;
  }

  .avoid-page-break {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  table {
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* Ensure table headers repeat on new pages */
  }

  tbody tr {
    page-break-inside: avoid; /* Prevent rows from splitting across pages */
  }
  @page {
    size: auto;
  }
}





.border-008B8B {
  border: 2px solid #054646;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-1000 {
  transition-duration: 1000ms;
}

.custom-input-cursor {
  color: #58ebeb;
  animation: blink 0.3s infinite;
}

.assitant-input-box{
  border: 1px solid #5eb6b6;
  color: #ffffff;
  padding: 5px;
  box-shadow: 0px 1px 1px ;
  margin-top: 10px;
  transition: 0.5s all;
  background: none;
}

.assitant-input-box:focus {
  color: #ffffff;
  font-weight: 900;
  box-shadow: 0 0 5px rgba(0, 255, 0, .2),
    inset 0 0 5px rgba(0, 255, 0, .1);
  background: none;
  animation: glow .8s ease-out infinite alternate;
}

@media screen {
  .pageBreak {
    border-top: 2px dashed #ccc;
    padding-top: 0.5rem; /* Adds some space above the border */
    padding-bottom: 0.5rem; /* Adds some space below the border */
  }
}

@keyframes glow {
  0% {
    border-color: #339933;
    box-shadow: 0 0 5px rgba(0, 255, 0, .2),
      inset 0 0 5px rgba(0, 0, 0, .1);
  }

  100% {
    border-color: #6f6;
    box-shadow: 0 0 20px rgba(0, 255, 0, .6),
      inset 0 0 10px rgba(0, 255, 0, .4);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

input {
height: fit-content;
}



.m-date-input::-webkit-calendar-picker-indicator{
  display: none;
  -webkit-appearance: none;
}