.detailView {
  flex: 1;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
  color: rgb(78, 107, 136);
  flex-direction: column;
  overflow: scroll;
  margin-top: 40px;
  margin-bottom: 30px;
  text-transform: uppercase;
  // height: 37rem;
  .main {
    background-color: white;
    height: 15rem;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0rem 0rem;
    border-bottom: solid 2px rgb(0 0 0 / 30%);

    .title {
      font-size: 26px;
      color: #002366;
      text-align: center;
      font-weight: 700;
    }

    .title2 {
      font-size: 14px;
      color: #002366;
      text-align: center;
      font-weight: 700;
    }
    .dp {
      height: 160px;
      width: 160px;
      margin: 10px auto;
      border: solid 1px;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .dataWraper {
    
    overflow: scroll;
  }

  .subTitle {
    font-size: 18px;
    font-weight: 600;
    padding: 0 20px;
    margin-top: 20px;
    color: #002366;
  }
  .detailItem {
    font-weight: 500;
    font-size: 16px;
    padding: 1px 40px;
    display: flex;
    align-items: center;
    .label {
      flex: 0 0 45%;
    }
    .value {
      margin-left: 10px;
    }
    svg {
      margin-left: 6px;
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
  .space {
    margin: 0 30px;
    :global {
      .ant-collapse-item {
        .ant-collapse-header {
          display: flex;
          align-items: center;
        }
        .ant-collapse-header-text {
          flex: 1;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .attendanceMonth {
    display: flex;
    justify-content: space-between;
  }
  .emptyMessage {
    text-align: center;
    margin: 20px 0;
  }
  .selectmessage {
    text-align: center;
    font-size: 20px;
    margin: auto;
  }
}

// // large screen view
// @media (min-width: 1025px) {
//   .main{
//     width: 29rem !important;
//   }

// }

// // tab view
// @media (min-width: 690px) and (max-width: 900px) {

//   .main{
//     width: 31rem !important;
//   }

// }
