.closeBtn {
    background: rgb(246, 107, 107);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    transition: 0.5s;
}

.closeBtn:hover {
    background: rgb(244, 95, 95);
    transform: translateY(5px);
}

/* Existing Styles for Table Headers and Cells */
.table th, .table td {
    width: auto !important;
    border: none;
    text-align: left;
}

.table th {
    background: #fc5dfc;
    height: 60px;
    color: aliceblue;
    font-size: 20px;
    text-transform: capitalize;
}

.table td {
    color: rgb(4, 4, 78);
    font-size: 15px;
    font-weight: 600;
    animation: fadein 0.7s;
}

.table tr:nth-child(2n) td {
    background: #ffdaff;
}


/* Animation for Fade-in */
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Additional Styles for Fixed Header */
.table-wrap {
    overflow-y: auto;
    max-height: 400px; /* Adjust the height as needed */
}

.table {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

.table thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: sticky;
    top: 0;
}

.table tbody {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tabl th, .tabl td {
    width: auto !important;
    text-align: left;
}

.tabl th {
    background: #fc5dfc;
    height: 60px;
    color: aliceblue;
    font-size: 12px;
    text-transform: capitalize;
}

.tabl td {
    color: rgb(4, 4, 78);
    font-size: 10px;
    font-weight: 600;
    animation: fadein 0.7s;
}

.tabl tr:nth-child(2n) td {
    background: #ffdaff;
}
.tabl tr:nth-child(2n-1) td {
    background: #fefefe;
}


/* Animation for Fade-in */
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Additional Styles for Fixed Header */
.tabl-wrap {
    overflow-y: auto;
    max-height: 400px; /* Adjust the height as needed */
}

.tabl {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

.tabl thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: sticky;
    top: 0;
}

.tabl tbody {
    display: table;
    width: 100%;
    table-layout: fixed;
}
