@import "../../../static/scss/colors";

.container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  position: absolute;

  .modal {
    display: flex;
    width: 97%;
    height: 97%;
    background-color: rgb(242, 242, 242);
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 20px;
    overflow: hidden;

  .hideData {
    display: none;
  }

  .tableView {
    flex: 0 0 70%;
    max-width: 70%;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 30px;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

    .tableHeader {
      background-color: rgb(0, 34, 103);
      display: flex;
      margin: 1px 0;

      border-radius: 20px 20px 0px 0px;

      h2 {
        color: #fff;
        flex: 2;
        font-size: 1.3rem;
        font-weight: 700;
        display: inline;
        margin: 10px 38px;
      }

      .searchWrap {
        display: flex;
        height: 40px;
        margin: 0;
        min-width: 40%;
        width: fit-content;
        border-radius: 25px;
        overflow: hidden;
        background: white;
        padding: 0 30px;
        margin: 10px 0;

        input {
          border: none;
          background: none;
          width: fit-content;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .dropDown {
        display: flex;
        height: 40px;
        margin: 10px 0;
        width: 200px;
        border-radius: 25px;
        overflow: hidden;

        .formFullWidth2 {
          width: 100%;
          height: 40px;
          box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
          border-radius: 30px;

          .ant-select-selector {
            border-radius: 20px;
            cursor: pointer !important;
          }

          >div {
            height: 100%;
            border: 0;

            span {
              display: flex;
              align-items: center;
              outline: 0;
              margin: auto 15px;
              border: 0;
              -webkit-highlight: none;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 23px;

              margin-top: 15px;

              &:focus {
                outline: 0;
                border: 0;
              }

              &:valid~label {
                top: -0;
                font-size: 12px;

                color: #016c11;

              }
            }
          }

          .error {
            height: unset;
          }
        }

        .group {
          position: relative;
          margin-left: 10px;
          width: 240px;
          height: 40px;
          padding: 1px 0px;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

          background: rgb(255, 255, 255);
          display: flex;

          border-radius: 20px;

          input,
          button {
            width: 100%;
          }

          input[type="password"],
          input[type="text"],


       

      


          label {
            position: absolute;
            pointer-events: none;
            left: 11px;
            top: -0;
            transition: 400ms ease all;
            margin-left: 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 23px;
            color: #006711;
          }

          .eye {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            color: $primary-color;
          }

          .error {
            transform: none;
            position: absolute;
            bottom: -20px;
            left: 25px;
            right: 0;
          }

        }

        span {
          margin-right: 20px;
          font-size: 20px;
        }

        button {
          background-color: transparent;
          height: 100%;
          width: 100%;
          color: #fff;
          display: flex;
          align-items: center;
          border: none;
          font-weight: 600;

          svg {
            height: 20px;
            width: 20px;
            border: solid 0.5px white;
            border-radius: 50%;
          }
        }
      }

      .exportBtn {
        display: flex;
        height: 50px;
        margin: 10px 0;
        width: fit-content;
        border-radius: 25px;
        overflow: hidden;

        button {
          font-size: 20px;
          background-color: transparent;
          height: 100%;
          width: 100%;
          color: #fff;
          display: flex;
          align-items: center;
          border: none;
          font-weight: 600;
        }
      }
    }
  }
}


.closeButton {
  height: 40px;
  width: 100px;
  position: absolute;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

  right: 1.9%;
  top: 2%;
  padding: 1px 0px;
  background-color: #fd0000;
  color: white;
  font-size: 16px;
  font-weight: 900;
  border: none;
  border-radius: 20px;
}
}