:root {
    --scrollbar-width: 10px;
    --scrollbar-radius: 20px;
    --scrollbar-track-color: rgba(0, 0, 0, 0.308);
    --scrollbar-thumb-color: rgb(73, 80, 81);
    --ptag-font-size: 0.8vw;
    --ptag-font-weight: 600;
}

#scrollBarEnabletable::-webkit-scrollbar {
    width: var(--scrollbar-width);
    border-radius: var(--scrollbar-radius);
    position: absolute;
    right: -10px;
}

#scrollBarEnabletable::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
    border-radius: var(--scrollbar-radius);
}

#scrollBarEnabletable::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: var(--scrollbar-radius);
    /* border: 3px solid rgba(0, 0, 0, 0.508); */
}



.td-hover-highlight:hover {
    background-color: #D1ECF1;
}

.hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }