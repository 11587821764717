.app-window {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    transition: 0.5s;
    animation: macAnimation 100ms ease-in-out;
    background: rgba(7, 7, 7, 0.801);
}

@keyframes macAnimation {
    0% {
        transform: scaleX(0) scaleY(0) translateY(200vw);
        opacity: 0;
    }

    100% {
        transform: scaleX(1) scaleY(1) translateY(0);
        opacity: 1;
    }
}

.bg-white-blur {
    background: rgba(255, 255, 255, 0.567);
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.autoWidthAnimation{
    transition: 0.5s;
}

.modalNameMain {
    position: absolute;
    font-weight: 700;
    align-self: center;
    top: 0;
    transition: 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
    background: rgba(255, 255, 255, 0.567);
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 10px 10px;
    padding-right: 15px;
    padding-left: 15px;
    color: rgb(2, 2, 57);
    font-size: 16px;
    cursor: text;
}