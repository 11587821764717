.classCard {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 10px;
  background-color: rgb(255, 255, 255);
  color: #002267;
  border-radius: 20px;
  padding: 20px 0;
  width: 450px;
  .titleRow {
    color: white;
    display: flex;
    justify-content: space-around;
    font-size: 26px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 10px 0;
    background-color: #002267;
    text-transform: uppercase;
    input {
      color: #FF7E3D;
      height: 50px;
      width: 50px;
      text-align: center;
      border: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      -moz-appearance: textfield;
      box-shadow: 0 0 4px #0000009f;
      &::placeholder {
        font-size: 16px;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }
  .periodTime {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 0 20px;
    div{
      display: flex;
      justify-content: space-around;
    }
    .text{
      margin: 8px;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
  
    }
    .time {
      border-radius: 30px;
      box-shadow: 0 0 14px -1px #a2a1a1;
    }
  }
}
