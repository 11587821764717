@import "../../../../static/scss/colors";

.addUserForm {
  background-color: #82c4ff;
  border-radius: 25px;
  display: flex;
  box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
  color: lightslategrey;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin: 10px;

  .addMultipleUser {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #18a0fb;
    text-transform: none;
    margin: 10px 10px;
    cursor: pointer;
  }

  .title {
    width: 100%;
    font-weight: 600;
    min-height: 5rem;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    color: #002267;
    padding: 30px 0px 10px;
    border-bottom: 0.4px solid #ededed;
  }

  .userForm {
    padding: 10px;
    flex-direction: row;
    display: flex;

    .error {
      font-size: 12px;
      line-height: 1;
      color: rgb(221, 73, 73);
      transform: translateY(-50%);
    }

    .formFullWidth {
      margin: 10px 0;
      width: 100%;
      height: 66px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);
      border-radius: 30px;

      .ant-select-selector {
        border-radius: 30px;
        cursor: pointer !important;
      }

      >div {
        height: 100%;
        border: 0;

        span {
          display: flex;
          align-items: center;
          outline: 0;
          margin: auto 15px;
          border: 0;
          -webkit-highlight: none;
          font-style: normal;
          font-weight: 900;
          font-size: 25px;
          line-height: 23px;
          text-transform: uppercase;

          &:focus {
            outline: 0;
            border: 0;
          }
        }
      }

      .error {
        height: unset;
      }
    }

    .formDistWidth {
      margin: 12px 0;
      flex: 0 0 54%;
      height: 55px;
      border-radius: 4px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

      .button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background: rgb(255, 255, 255);
        border-radius: 40px;

        svg {
          margin-right: 10px;
          height: 50%;
          width: auto;
        }

        input {
          display: none;
        }
      }

      .uploadButton {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 11px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        text-transform: uppercase;
        color: #c0c0c0;
      }

      .error {
        height: unset;
      }
    }

    .btns {
      display: flex;
      width: 100%;
    }

    .btnClear {
      margin: 16px auto;
      flex: 0 0 40%;
      height: 55px;
      border-radius: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      border: none;
      cursor: pointer;
      font-style: bold;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #ffffff;
      background: #810000;
    }

    .formButtonWidth {
      margin: 16px auto;
      flex: 0 0 40%;
      height: 55px;
      border-radius: 4px;

      >button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        border: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        background: #007f1e;

        svg {
          margin-right: 10px;
          height: 50%;
          width: auto;
        }
      }
    }

    .formPinWidth {
      margin: 12px auto;
      flex: 0 0 45%;
      height: 55px;
      border-radius: 4px;
      box-shadow: 1px 4px 12px 5px rgb(0 0 0 / 10%);

      .error {
        height: unset;
      }
    }

    .group {
      position: relative;
      background: white;
      margin-bottom: 2rem;
      display: flex;
      align-items: flex-end;
      border-radius: 30px;

      input,
      button {
        width: 100%;
      }

      input[type="password"],
      input[type="text"] {
        background: none;
        color: #000000;
        font-family: 'Times New Roman', Times, serif;
        margin-left: 30px;
        font-weight: 900;

        font-size: 30px;
        padding: 10px;

        display: block;
        border: none;

        &:focus {
          outline: none;
        }

        &:focus~label,
        &:valid~label {
          top: -0;
          font-size: 12px;

          color: #000000;
        }
      }

      input[type="password"] {
        letter-spacing: 0.3em;
      }

      label {
        position: absolute;
        pointer-events: none;
        left: 11px;
        top: 10px;
        transition: 400ms ease all;
        margin-left: 15px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        color: #1d1d1d;
        text-transform: uppercase;
      }

      .eye {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        color: $primary-color;
      }

      .error {
        transform: none;
        position: absolute;
        bottom: -20px;
        left: 25px;
        right: 0;
      }
    }
  }
}